@mixin footer-button-popup-menu($bottom: 54px) {
  text-align: left;
  background: rgba(17 17 17 / 80%);
  bottom: $bottom;

  a {
    color: $white5;
    padding: 5px 20px 5px 39px;
    position: relative;
    font-size: 12px;

    &:focus {
      color: $white5;
      background: transparent;
      outline: 1px solid $white1;
    }

    &:hover {
      color: $white11;
      background: $white6;
    }
  }

  @include m(checked) {
    &::before {
      content: '';
      @include checked-icon;

      position: absolute;
      left: 10px;
      top: 6px;
    }
  }

  @include m(disabled) {
    cursor: not-allowed;
    color: $white15 !important;
  }
}
