
@mixin size($args-list...) {
  @if length($args-list)==0 {
      @error "Width, height or line-height are expected";
  }
  @if length($args-list)>=1 {
      $width: nth($args-list, 1);
      @if _is-size($width) {
          width: $width;
      }
      @else if(type-of($width) != "null"){
          @error "`#{$width}` is not a valid length for the `$width` argument "+"in the `size` mixin.";
      }
  }
  @if length($args-list)>=2 {
      $height: nth($args-list, 2);
      @if _is-size($height) {
          height: $height;
      }
      @else if(type-of($height) != "null"){
          @error "`#{$height}` is not a valid length for the `$height` argument "+"in the `size` mixin.";
      }
  }
  @if length($args-list)==3 {
      $line-height: nth($args-list, 3);
      @if _is-size($line-height) {
          line-height: $line-height;
      }
      @else if(type-of($line-height) != "null"){
          @error "`#{$line-height}` is not a valid length for the `$line-height` argument "+"in the `size` mixin.";
      }
  }
}
