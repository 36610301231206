%horizontal-line {
    @include make-line(100%, 1px, $black5);
    content: "";
}

%horizontal-line2 {
    @include make-line(100%, 1px, $black10);
    content: "";
}

%vertical-line {
    @include make-line(1px, 100%, $black5);
    content: "";
}