@mixin position($position, $box-edge-values...) {
  @if length($box-edge-values) ==0 {
    @error "Offset arguments are missing";
  }
  @if type-of(nth($box-edge-values, 1)) == 'list' {
    $box-edge-values: nth($box-edge-values, 1);
  }
  @if length($box-edge-values) >4 {
    @error "Offset arguments(top, right, bottom, left) is greater than 4";
  }
  $box-edge-values: _unpack-shorthand($box-edge-values);
  $offsets: (
    top: nth($box-edge-values, 1),
    right: nth($box-edge-values, 2),
    bottom: nth($box-edge-values, 3),
    left: nth($box-edge-values, 4),
  );
  position: $position;
  @each $offset, $value in $offsets {
    @if _is-length($value) {
      #{$offset}: $value;
    }
  }
}

@mixin absolute($args-list...) {
  @include position(absolute, $args-list...);
}

@mixin relative($args-list...) {
  @include position(relative, $args-list...);
}

@mixin fixed($args-list...) {
  @include position(fixed, $args-list...);
}

@mixin background-scale($size) {
  background-size: 832px * $size 768px * $size;
}
