@mixin scroll-bar-1 {
  &::-webkit-scrollbar-track {
    border-radius: 12px;
    background-color: #f2f2f7;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 1px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #BABACC;
  }
}

@mixin scroll-bar-white-black {
  &::-webkit-scrollbar-track {
    border-radius: 12px;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 1px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.54);
  }
}
