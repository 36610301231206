%ax-outline-pattern1 {
    outline: 2px solid transparent;
    outline-offset: 2px;
    transition: 0.2s all ease-in;
    &:focus {
        outline: 2px solid $white1;
        outline-offset: 1px;
    }
}

%ax-outline-pattern2 {
    outline: 2px solid transparent;
    outline-offset: 2px;
    transition: 0.2s all ease-in;
    &:focus {
        outline: 2px solid $blue1;
        outline-offset: 1px;
    }
}
