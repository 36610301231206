html,
body {
  height: 100%;
  width: 100%;
  overflow: hidden;
  font-size: 16px;
  padding: 0;
  margin: 0;
  font-family: system-ui, "SF Pro", "Segoe UI", "Almaden Sans", Roboto, Ubuntu, Helvetica, Arial;
  color: #4a4a4a;
}

#root {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

// fix input#cdn_path miss display: none
// Src: https://git.zoom.us/web/web/-/blob/release/web-main/src/main/webapp/WEB-INF/ftl/webclient/wc_meeting_v2.ftl#L179
#cdn_path {
  display: none;
}

.display-none {
  display: none;
}

/*common*/
.showme {
  visibility: visible;
}

.hideme {
  visibility: hidden;
}

label {
  font-weight: normal;
}

.btn-sm {
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.btn-default:hover {
  color: #333;
  background-color: #ebebeb;
  border-color: #adadad;
}

.btn-primary {
  background-color: #0e71eb;
  border-color: #0e71eb;
}

.btn-primary:hover {
  background-color: #2681f2;
  border-color: #2681f2;
}

a, button {
  outline: 2px solid transparent;
  outline-offset: 2px;
  transition: 0.2s all ease-in;
  &:focus {
    outline: 2px solid $blue1;
    outline-offset: 1px;
  }
}
a {
  &:hover {
    outline: 0 solid transparent;
  }
}

/*************************header******************************/

#wc-header {
  height: 48px;
  width: 100%;
}

.meeting-tip {
  text-align: center;
  padding-top: 23px;
}

.sharing-tip {
  position: absolute;
  top: 64px;
  text-align: center;
  width: 100%;
}

.sharing-tip div {
  display: inline-block;
  font-size: 13px;
  line-height: 24px;
  color: #3a3c36;
  width: 270px;
  text-align: center;
  background-color: #4bc800;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  border-radius: 40px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 24px;
  padding: 0 15px;
}

.sharing-tip span {
  opacity: 0.5;
  color: #1f4707;
}

.meeting-tip span {
  margin: 0 10px;
}

.meeting-tip span:last-child {
  max-width: 650px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#wc-container-left {
  flex: 1;
  position: relative;
  // float: left;
  //height: 100vh;
  //height: -webkit-fill-available; // hack for mobile safari
  height: 100%;
  -webkit-user-select: none;
  /* webkit (safari, chrome) browsers */
  -moz-user-select: none;
  /* mozilla browsers */
  -khtml-user-select: none;
  /* webkit (konqueror) browsers */
  -ms-user-select: none;
  //overflow: hidden;
  transition: all 0.2s ease-in;
  text-align: center;
  background: #0D0D0D;
}
#wc-container-left{
  &.bloom {
    background: linear-gradient(180deg, #143584 25.84%, #00031F 82.57%);
  }
  &.agave{
    background: linear-gradient(180deg, #11565A 25.84%, #00171F 82.57%);
  }
  &.rose{
    background: linear-gradient(180deg, #84192F 25.84%, #1F0000 82.57%);
  }
}


#participant-window {
  color: $black18;
  position: relative;
  background-color: #ffffff;
  .participants-section-container {
    height: 464px;
  }
}

#transcription-window {
  color: $black18;
  position: relative;
  background-color: #ffffff;
}

#wc-container-right {
  color: $black18;
  // height: 100%;
  // *height: auto;
  // float: left;
  position: relative;
  z-index: 20;
  background-color: #ffffff;
}

// #participant-window div.list-group,
// #wc-container-right div.list-group {
//     font-size: 13px;
//     font-weight: 600;
//     color: #535353;
// }

.icon-close {
  background: url(../image/wc_sprites.png) no-repeat -388px -36px;
  background-size: 416px 384px;
  width: 8px;
  height: 8px;
  cursor: pointer;
}

#participant-window div.list-group,
#wc-container-right div.list-group {
  font-size: 13px;
  font-weight: 600;
  color: #535353;
}

/* .list-group .title {
    background: transparent;
} */

.list-group .bottom {
  /*position: absolute;*/
  /*bottom: 0;*/
  /*width: 100%;*/
  /*height: 52px;*/
  /*padding: 11px 0 0 0;*/
  /*margin: 0;*/
  /*text-align: center;*/
}

// .list-group .bottom .divider {
//     margin: 4px 0;
// }

// .list-group .bottom li>a {
//     padding: 3px 15px;
// }

// .list-group .mute-all {
//     cursor: pointer;
//     display: inline-block;
//     vertical-align: middle;
//     height: 30px;
//     width: 80px;
//     line-height: 30px;
//     color: #2d8cff;
//     margin-right: 5px;
//     font-weight: 500;
// }

// .list-group .lock-meeting {
//     cursor: pointer;
//     display: inline-block;
//     vertical-align: middle;
//     height: 30px;
//     width: 105px;
//     line-height: 30px;
//     color: #2d8cff;
//     margin-left: 5px;
//     font-weight: 500;
// }

// .list-group .mute-all:hover,
// .list-group .lock-meeting:hover {
//     background: #fff;
// }

// .participants-ul {
  // height: auto;
// }

.participants-me,
.participants-ul {
  font-weight: 400;
  position: relative;
  font-size: 12px;
  line-height: 18px;
  padding: 0;
  text-align: left;
  list-style: outside none none;
  margin-bottom: 0px;
  // max-height: 100%;
}

/*#wc-container-right ul li,*/

.participants-li {
  padding: 5px 10px 5px 8px;
}

.participants-li-selected {
  background-color: #bde6f9;
}

/*#wc-container-right ul li:hover,*/

.participants-li:hover {
  background: #f0f0f0;
}

/*#wc-container-right ul li img,*/

.participants-img {
  border: 1px solid #fff;
  height: 24px;
  margin-right: 6px;
  width: 24px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

// .participants-mute {
//     display: inline-block;
//     background: url(../image/wc_sprites.png) no-repeat -102px -98px;
//     background-size: 416px 384px;
//     width: 12px;
//     height: 20px;
//     float: right;
//     margin-top: 2px;
//     margin-right: 7px;
// }

// .participants-mute-phone {
//     display: inline-block;
//     background: url(../image/wc_sprites.png) no-repeat -3px -99px;
//     background-size: 416px 384px;
//     width: 18px;
//     height: 19px;
//     float: right;
//     margin-top: 3px;
//     margin-right: 4px;
// }

// .participants-mute-animation {
//     display: inline-block;
//     background: url(../image/wc_sprites.png) no-repeat -102px -98px;
//     background-size: 416px 384px;
//     width: 12px;
//     height: 20px;
//     float: right;
//     margin-top: 2px;
//     margin-right: 7px;
//     animation: mute 1.5s steps(1) infinite;
//     -moz-animation: mute 1.5s steps(1) infinite;
//     -webkit-animation: mute 1.5s steps(1) infinite;
// }

// .participants-mute-phone-animation {
//     display: inline-block;
//     background: url(../image/wc_sprites.png) no-repeat -3px -99px;
//     background-size: 416px 384px;
//     width: 18px;
//     height: 19px;
//     float: right;
//     margin-top: 3px;
//     margin-right: 4px;
//     animation: mute-phone 1.5s steps(1) infinite;
//     -moz-animation: mute-phone 1.5s steps(1) infinite;
//     -webkit-animation: mute-phone 1.5s steps(1) infinite;
// }

// .participants-unmute {
//     margin-right: 3px;
//     display: inline-block;
//     background: url(../image/wc_sprites.png) no-repeat -121px -98px;
//     background-size: 416px 384px;
//     width: 21px;
//     height: 21px;
//     float: right;
//     margin-top: 2px;
// }

// .participants-unmute-phone {
//     margin-right: 3px;
//     display: inline-block;
//     background: url(../image/wc_sprites.png) no-repeat -25px -98px;
//     background-size: 416px 384px;
//     width: 21px;
//     height: 21px;
//     float: right;
//     margin-top: 2px;
// }

// .participant-video {
//     display: inline-block;
//     background: url(../image/wc_sprites.png) no-repeat;
//     background-size: 416px 384px;
//     width: 23px;
//     height: 13px;
//     float: right;
//     margin-top: 3px;
//     margin-right: 7px;
// }

// .participants-video-started {
//     height: 16px;
//     background-position: -286px -184px;
// }

// .participant-video-stopped {
//     height: 20px;
//     background-position: -310px -184px;
// }

// .participants-raise-hand {
//     margin-right: 7px;
//     display: inline-block;
//     background: url(../image/wc_sprites.png) no-repeat -72px -152px;
//     background-size: 416px 384px;
//     width: 16px;
//     height: 16px;
//     float: right;
//     margin-top: 4px;
// }

.lower-hand {
  position: absolute;
  background: #2c8dff;
  color: #ffffff;
  padding: 5px;
  border-radius: 3px;
  cursor: pointer;
  vertical-align: top;
  height: 27px;
}

.lower-hand-icon {
  display: inline-block;
  background: url(../image/wc_sprites.png) no-repeat -72px -152px;
  background-size: 416px 384px;
  width: 16px;
  height: 16px;
  margin-top: 1px;
  margin-right: 4px;
  vertical-align: top;
  margin-left: 3px;
}

@keyframes mute {
  0%,
  20%,
  35% {
    background-position: -174px -98px;
  }
  50% {
    background-position: -198px -98px;
  }
  65% {
    background-position: -222px -98px;
  }
  80% {
    background-position: -246px -98px;
  }
  95% {
    background-position: -270px -98px;
  }
  100% {
    background-position: -174px -98px;
  }
}

@-moz-keyframes mute {
  0%,
  20%,
  35% {
    background-position: -174px -98px;
  }
  50% {
    background-position: -198px -98px;
  }
  65% {
    background-position: -222px -98px;
  }
  80% {
    background-position: -246px -98px;
  }
  95% {
    background-position: -270px -98px;
  }
  100% {
    background-position: -174px -98px;
  }
}

@-webkit-keyframes mute {
  0%,
  20%,
  35% {
    background-position: -174px -98px;
  }
  50% {
    background-position: -198px -98px;
  }
  65% {
    background-position: -222px -98px;
  }
  80% {
    background-position: -246px -98px;
  }
  95% {
    background-position: -270px -98px;
  }
  100% {
    background-position: -174px -98px;
  }
}

@keyframes mute-phone {
  0% {
    background-position: -3px -99px;
  }
  50% {
    background-position: -51px -99px;
  }
  80% {
    background-position: -75px -99px;
  }
  100% {
    background-position: -3px -99px;
  }
}

@-moz-keyframes mute-phone {
  0% {
    background-position: -3px -99px;
  }
  50% {
    background-position: -51px -99px;
  }
  80% {
    background-position: -75px -99px;
  }
  100% {
    background-position: -3px -99px;
  }
}

@-webkit-keyframes mute-phone {
  0% {
    background-position: -3px -99px;
  }
  50% {
    background-position: -51px -99px;
  }
  80% {
    background-position: -75px -99px;
  }
  100% {
    background-position: -3px -99px;
  }
}

@keyframes telephone-unmuted-animated-path-blink-1 {
  0% {
    opacity: 1;
  }
  49% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  74% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
}

@keyframes telephone-unmuted-animated-path-blink-2 {
  0% {
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.telephone-unmuted-animated-path-1 {
  animation: telephone-unmuted-animated-path-blink-1 0.8s infinite;
}
.telephone-unmuted-animated-path-2 {
  animation: telephone-unmuted-animated-path-blink-2 0.8s infinite;
}


.rec-section {
  position: absolute;
  top: 11px;
  left: 70px;
  z-index: 1;
}
/*host recording start*/
.stop-recording {
  width: 120px;
  height: 26px;
  vertical-align: middle;
  border-radius: 6px;
  background-color: rgba(9, 10, 10, 0.8);
  font-size: 12px;
  color: #fff;
  margin-left: 10px;
}

.record-text {
  display: inline-block;
  float: right;
  margin-top: 4px;
  margin-right: 8px;
}

.rec-img-enabled {
  background: url(../image/wc_sprites.png) no-repeat;
  background-size: 416px 384px;
  background-position: -71px -353px;
  width: 18px;
  height: 14px;
  display: inline-block;
  margin: 5px 3px 5px 5px;
  animation: rec-cloud-animation1 1.5s steps(1) infinite;
}
@keyframes rec-cloud-animation1 {
  0%,
  100% {
    background-position: -71px -353px;
  }
  50% {
    background-position: -103px -353px;
  }
}
/*host recording end*/

/*attendee recording or host local recording start*/
.rec-img-readonly {
  display: inline-block;
  font-size: 13px;
  color: #fff;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.81);
  vertical-align: middle;
}

.rec-img-readonly__icon {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.7);
  margin-right: 5px;
  display: inline-block;
  background: url(../image/wc_sprites.png) no-repeat -192px -32px;
  background-size: 416px 384px;
  width: 15px;
  height: 15px;
  position: relative;
  top: 5px;
  left: 2px;
  animation: rec-readonly-animation 1.5s steps(1) infinite;
}

.rec-img-readonly__text {
  top: 3px;
  position: relative;
}

@keyframes rec-readonly-animation {
  0%,
  100% {
    background-position: -192px -32px;
  }
  50% {
    background-position: -208px -32px;
  }
}
/*attendee recording or host local recording end*/

#wc-container-left .tip {
  height: 44px;
  position: relative;
}

.asntip {
  text-align: left;
  display: inline-block;
  font-size: 12px;
  line-height: 48px;
  color: #fff;
  max-width: 250px;
  background-color: rgba(26, 26, 26);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 48px;
  padding: 0 15px 0 20px;
  position: absolute;
  right: 0px;
  left: 0px;
  margin: auto;
  z-index: 20;
}

#wc-footer .novideo {
  background-position: -72px -209px;
}

#wc-footer .p180 {
  background-position: -181px -186px;
}

#wc-footer .p360 {
  background-position: -126px -186px;
}

#wc-footer .p720 {
  background-position: -72px -184px;
  height: 22px;
  margin-top: 7px;
  transform: translateX(1px);
}

.full-screen-icon {
  cursor: pointer !important;
  border-bottom: none;
  //background-color: #111;
  border-radius: 8px;
  display: flex;
}

.full-screen .full {
  display: inline-block;
  background: url(../image/wc_sprites.png) no-repeat -321px -97px;
  background-size: 416px 384px;
  width: 13px;
  height: 13px;
  position: relative;
  border: 'none';
}

.full-screen .full:hover {
  display: inline-block;
  background: url(../image/wc_sprites.png) no-repeat -337px -97px;
  background-size: 416px 384px;
  width: 13px;
  height: 13px;
  position: relative;
  border: 'none';
}

.full-screen .no-full {
  display: inline-block;
  background: url(../image/wc_sprites.png) no-repeat -289px -97px;
  background-size: 416px 384px;
  width: 13px;
  height: 13px;
  position: relative;
  border: 'none';
}

.full-screen .no-full:hover {
  display: inline-block;
  background: url(../image/wc_sprites.png) no-repeat -305px -97px;
  background-size: 416px 384px;
  width: 13px;
  height: 13px;
  position: relative;
  border: 'none';
}

#wc-header .right .img-participants {
  background: url(../image/wc_sprites.png) no-repeat;
  background-size: 416px 384px;
  background-position: -227px -37px;
  width: 18px;
  height: 7px;
  display: block;
  margin: 0 auto;
}

.dropdown-menu {
  & > .dropdown-item {
    text-decoration: none;
    color: #333;
    display: block;
    padding: 3px 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 1.42857143;

    &:hover {
      background-color: #f5f5f5;
      color: #262626;
      text-decoration: none;
    }
  }
}

#wc-header li.dropdown {
  cursor: default;
  width: 180px;
  margin-left: 20px;
}

.full-screen {
  cursor: pointer;
  width: 26px;
  height: 26px;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.join-loading {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
}

.join-loading div {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.show-tip {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  z-index: 9999;
  font-size: 13px;
  min-width: 340px;
  max-width: 600px;
  height: 66px;
  color: #fff;
  padding-left: 36px;
  background-color: rgba(0, 0, 0, 0.7);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  white-space: nowrap;
}

.show-tip-i {
  font-size: 18px;
  color: #2da5ff;
  margin-right: 10px;
}

.show-tip-txt {
  position: relative;
  top: 15px;
  margin-right: 20px;
}

.show-sharing {
  left: 50%;
  top: 50%;
  margin-top: -19px;
  margin-left: -125px;
  z-index: 9999;
  position: absolute;
  font-size: 13px;
  line-height: 18px;
  color: #ffffff;
  width: 250px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  border-radius: 40px;
  padding: 10px;
  white-space: nowrap;
}

/*************************container******************************/

#wc-content {
  background-color: #131619;
  height: 100%;
  position: relative;
  //overflow: hidden;
  display: flex;
}

#wc-container {
  height: 100%;
  transition: all 0.2s ease-in;
  min-height: 105px;
  overflow: hidden;
  overflow-y: auto;
  text-align: center;
  white-space: nowrap;
}

// #wc-container:after {
//     content: "";
//     display: inline-block;
//     vertical-align: middle;
//     min-height: 100%;
//     width: 0;
// }

#wc-container .attendee-content {
  margin: 0;
  padding: 0;
  font-size: 0;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
}

#wc-container .wrap {
  margin: 0 auto;
  text-align: center;
}

#wc-container .attendee {
  font-size: 12px;
  height: 210px;
  width: 180px;
  display: inline-block;
  margin: 0 3px 6px;
  position: relative;
  overflow: hidden;
  border-radius: 3px 3px;
  box-sizing: content-box;
  border: solid 3px white;
}

#wc-container .outline {
  border: solid 3px #4bc800;
  height: 210px;
  width: 180px;
  box-shadow: inset 0 0 2px 2px rgba(75, 2000, 0, 0.2);
}

#wc-container .attendee .avatar {
  background-color: #b8cce2;
  width: 180px;
  height: 180px;
  overflow: hidden;
}

#wc-container .attendee .avatar img {
  width: 180px;
  height: 180px;
}

#wc-container .attendee-bottom {
  font-size: 12px;
  color: #ffffff;
  line-height: 30px;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
}

#wc-container .attendeename {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.is_host {
  vertical-align: top;
  padding-left: 3px;
}

.has-audio,
.no-audio {
  display: inline-block;
  background: url(../image/wc_sprites.png);
  background-size: 416px 384px;
  margin-right: 6px;
  vertical-align: middle;
}

.has-audio {
  background-position: -387px -122px;
  width: 9px;
  height: 12px;
}

.no-audio {
  background-position: -401px -50px;
  width: 13px;
  height: 12px;
}

#wc-container .attendee-bottom div {
  background: rgba(0, 0, 0, 0.5);
  text-transform: capitalize;
}

#wc-container .attendee .rn-ex > span {
  display: block;
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: #0b607c;
  height: 30px;
}

#wc-container .attendee .rn-ex {
  width: 100%;
  bottom: 30px;
  position: absolute;
  height: 30px;
  color: #fff;
}

#wc-container .attendee .rn-ex div {
  background: rgba(0, 0, 0, 0.5);
  height: 30px;
}

#wc-container .attendee .img-rename {
  display: inline-block;
  background: url(../image/wc_sprites.png) no-repeat 0 -32px;
  background-size: 416px 384px;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  position: relative;
  top: 5px;
}

#wc-container .attendee .img-expel {
  display: inline-block;
  background: url(../image/wc_sprites.png) no-repeat -34px -34px;
  background-size: 416px 384px;
  width: 16px;
  height: 16px;
  margin-left: 15px;
  margin-right: 5px;
  position: relative;
  top: 3px;
}

#wc-container .mute-status {
  width: auto;
  height: 22px;
  position: absolute;
  top: 6px;
  right: 6px;
  cursor: pointer;
  padding: 0 7px;
  background: rgba(36, 36, 36, 0.88);
  backdrop-filter: blur(20px);
  border-width: 0;
  border-radius: 7px;
  color: rgba(255, 255, 255, 0.62);
  font-size: 12px;
  &:hover {
    color: #f5f5f5;
  }
  &:focus,
  &:active {
    background: rgba(79, 79, 79, 0.88);
    color: #f5f5f5;
  }
}

#wc-container .mute-status div {
  border-radius: 32px;
  background: #333;
  width: 52px;
  height: 52px;
}

#wc-container .operation {
  color: white;
  padding-right: 5px;
  padding-left: 5px;
  border-right: 1px solid #ffffff;
  cursor: pointer;
}

.rename-btn,
.expel-btn {
  cursor: pointer;
  z-index: 3;
}

#renameDialog .modal-dialog {
  width: 400px;
}

#newname {
  display: inline-block;
  width: 100%;
  height: 32px;
  line-height: 32px;
  border-radius: 8px;
  padding: 0 12px;
  box-shadow: unset;
}

#lockConfDialog .lock-warn {
  float: left;
  cursor: pointer;
  margin-left: 10px;
}

#lockConfDialog .lock-warn input {
  position: relative;
  margin-right: 5px;
  top: 2px;
}

/*************************dialog-join**********************/

.show-joindialog #wc-container,
.show-invitedialog #wc-container {
  height: calc(100% - 90px - 630px);
  transition: height 0.2s ease-in;
}

.show-joindialog #dialog-join,
.show-invitedialog #dialog-invite {
  bottom: 0;
  transition: all 0.2s ease-in;
}

.Select {
  width: 220px;
}

.diaout-layer {
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  width: 100%;
  height: 430px;
  position: absolute;
  left: 0;
  z-index: 10;
  bottom: -430px;
  min-width: 200px;
  background-color: rgba(38, 40, 42, 0.95);
}

.diaout-layer .left {
  display: inline-block;
  float: left;
  width: 48%;
}

#dialog-join .left .content {
  width: 500px;
}

#dialog-join .support-callout,
#dialog-invite .support-callout {
  display: block !important;
  float: none !important;
  margin: 0 auto;
}

.diaout-layer .content {
  width: 450px;
  margin: 0 auto;
  font-size: 14px;
}

.diaout-layer #tollnumber {
  max-height: 145px;
  overflow-x: hidden;
  overflow-y: auto;
  margin-bottom: 8px;
}

.diaout-layer #tollnumber li,
.diaout-layer #didnumber span {
  font-size: 16px;
  color: #f5a623;
  margin-top: 2px;
}

.diaout-layer .content .presscode,
.diaout-layer .content .show-psd .password {
  color: #f5a623;
  margin-left: 10px;
}

#dialInDialog .password {
  font-weight: 600;
  margin-left: 10px;
}

#dialInDialog .mark {
  font-weight: 600;
}

.diaout-layer .content .attendeeId {
  color: #f5a623 !important;
}

.diaout-layer .content .link-did {
  margin-left: 10px;
  color: #70c2ff;
  cursor: pointer;
  text-decoration: none;
}

.diaout-layer #tollnumber .country {
  color: #ffffff;
  font-size: 14px;
  margin-left: 10px;
}

.diaout-layer .content .mark {
  color: #88875b;
}

.diaout-layer .content ul {
  list-style: none;
  padding-left: 0;
  margin: 6px 0 18px 0;
}

.diaout-layer .connected {
  font-size: 13px;
  color: #ffffff;
  line-height: 18px;
}

.diaout-layer .content .link-plan {
  color: #70c2ff;
  cursor: pointer;
  text-decoration: none;
}

.diaout-layer .content .steps {
  margin-top: 10px;
  opacity: 0.8;
}

.diaout-layer .content li a {
  text-decoration: none;
  color: #70c2ff;
}

.diaout-layer .content .steps li {
  margin: 3px 0;
}

.diaout-layer .content .connected span {
  font-weight: 600;
  color: #88875b;
}

.diaout-layer .content .attendee-code {
  margin-top: 18px;
}

.diaout-layer .content .presstip {
  border-radius: 4px;
  height: 35px;
  text-align: center;
  padding-top: 6px;
  margin-left: -17px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.diaout-layer .content .country-select {
  display: inline-block;
  width: 45%;
}

.diaout-layer .title {
  margin-bottom: 40px;
  text-align: center;
  vertical-align: top;
  font-size: 24px;
  color: #f3f4f5;
  line-height: 52px;
  height: 32px;
}

.diaout-layer .middle {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  width: 4%;
}

.diaout-layer .middle .line {
  display: inline-block;
  height: 110px;
  width: 1px;
}

.diaout-layer .middle span {
  display: block;
}

.diaout-layer .middle .line-up {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #f3f4f5);
}

.diaout-layer .middle .line-down {
  background-image: linear-gradient(to top, rgba(255, 255, 255, 0), #f3f4f5);
  margin-top: 6px;
}

.diaout-layer .left .img-down {
  display: inline-block;
  background: url(../image/wc_sprites.png) no-repeat;
  background-size: 416px 384px;
  background-position: -68px -36px;
  width: 24px;
  height: 24px;
  position: relative;
  top: 5px;
  right: 10px;
}

.diaout-layer .left .img-email {
  display: inline-block;
  background: url(../image/wc_sprites.png) no-repeat;
  background-size: 416px 384px;
  background-position: -130px -38px;
  position: relative;
  top: 1px;
  right: 8px;
  width: 28px;
  height: 20px;
}

.diaout-layer .middle .img-down {
  display: inline-block;
  background: url(../image/wc_sprites.png) no-repeat;
  background-size: 416px 384px;
  background-position: -161px -40px;
  width: 30px;
  height: 24px;
  margin-top: 8px;
  cursor: pointer;
}

.diaout-layer .top .img-down {
  display: inline-block;
  background: url(../image/wc_sprites.png) no-repeat;
  background-size: 416px 384px;
  background-position: -161px -40px;
  width: 30px;
  height: 24px;
  margin-top: 8px;
  cursor: pointer;
}

.diaout-layer .top {
  text-align: center;
}

@media screen and (max-width: 600px) {
  .diaout-layer .top {
    text-align: left;
  }
}

.diaout-layer .right .img-down {
  display: inline-block;
  background: url(../image/wc_sprites.png) no-repeat;
  background-size: 416px 384px;
  background-position: -101px -33px;
  width: 22px;
  height: 28px;
  position: relative;
  top: 5px;
  right: 6px;
}

.diaout-layer .right {
  display: inline-block;
  float: right;
  width: 48%;
}

.diaout-layer .right .btn-group {
  display: inline-block;
}

.diaout-layer .right .btn-call,
.diaout-layer .left .btn-call,
#callme,
#inviteBtn,
#dialog-invite #btn-cancle {
  /*background-image: linear-gradient(-180deg, #e5e3e3 0%, #d2cfcf 100%);*/
  border-radius: 3px;
  width: 250px;
  height: 44px;
  color: #fff;
  font-size: 16px;
  line-height: 30px;
  border-color: #408b13;
  background-color: #408b13;
  margin-top: 40px;
}

#dialog-invite #btn-cancle {
  margin-top: 50px;
}

#inviteBtn {
  width: 100%;
}

#select-all {
  border-radius: 3px;
  width: 250px;
  height: 44px;
  color: #fff;
  font-size: 16px;
  line-height: 30px;
  border-color: #408b13;
  background-color: #408b13;
  width: 100%;
}

#hangup {
  border-radius: 3px;
  width: 250px;
  height: 44px;
  color: #fff;
  font-size: 16px;
  line-height: 30px;
  border-color: #408b13;
  background-color: #408b13;
}

#callme:hover,
#hangup:hover,
#pc-join:hover,
#inviteBtn:hover,
#select-all:hover,
#dialog-invite #btn-cancle:hover {
  border-color: #377a0f;
  background: #377a0f;
}

#pc-join {
  display: block;
  margin: 0 auto;
  font-size: 16px;
  color: #fff;
  line-height: 30px;
  height: 44px;
  min-width: 250px;
}

#dialog-invite .right .btn-call {
  width: 430px !important;
  margin-top: 42px !important;
}

#dialog-invite .left .btn-call {
  width: 430px !important;
  margin-top: 10px !important;
}

.diaout-layer .right .remember {
  margin: 16px 0 30px 0;
}

.remember label {
  cursor: pointer;
  font-size: 13px;
}

#remeber-number {
  position: relative;
  margin-right: 6px;
  top: 3px;
}

.diaout-layer .right .invitee {
  width: 430px;
}

.lock-warn label {
  font-size: 13px;
  color: #333;
  margin: 0;
}

#invite_email {
  font-size: 13px;
  height: 130px;
  width: 100%;
  color: #f3f4f5;
  line-height: 18px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  border-width: 0;
  resize: none;
  margin-bottom: 16px;
  cursor: pointer;
}

// .diaout-layer #dispalyname {
//     width: 100%;
//     margin-bottom: 16px;
// }

.diaout-layer button[value='invite'] {
  margin-top: 30px;
}

.diaout-layer button[value='cancle'] {
  margin-top: 15px;
}

.diaout-layer .indication {
  width: 600px;
  margin: 0 auto;
  text-align: center;
}

.diaout-layer .indication p {
  font-size: 13px;
}

.diaout-layer .indication div {
  color: #76bcf0;
  margin-top: 25px;
  cursor: pointer;
}

#callout-status {
  text-align: center;
}

#callout-status span {
  display: block;
}

#callout-status .connectiong {
  font-size: 14px;
  color: #ffffff;
}

#callout-status .callout-phonenumber {
  font-size: 20px;
  color: #ffffff;
  margin-top: 30px;
  margin-bottom: 45px;
}

/*************************status******************************/

.meeting-status {
  position: absolute;
  right: 35px;
  z-index: 100;
  top: 100px;
  max-width: 300px;
}

.status {
  width: 200px;
  font-size: 13px;
  opacity: 0.89;
  color: #ffffff;
  line-height: 19px;
  margin-bottom: 8px;
  background: #7bad60;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.27);
  border-radius: 50px;
  text-align: center;
  height: 33px;
  padding: 7px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  float: right;
}

.netword-error {
  width: 285px;
  background-color: #dac931;
}

/*************************footer******************************/

#wc-footer > div,
#wc-footer span {
  display: inline-block;
}

#wc-footer .left-tool-item {
  display: inline-block;
  position: relative;
  height: 100%;
}

#wc-footer .join-audio,
#wc-footer .mute {
  height: 100%;
  cursor: pointer;
  float: left;
  border: none;
  min-width: 90px;
  max-width: 110px;
  background-color: transparent;
}

#wc-footer .start-video,
#wc-footer .stop-video,
#wc-footer .about-video {
  float: left;
  /* width: 80px; */
  height: 100%;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

#wc-footer .active-animate {
  color: #fff;
  -webkit-animation: flash 1s forwards;
  -o-animation: flash 1s forwards;
  animation: flash 1s forwards;
}

@keyframes flash {
  0% {
    background: transparent;
    color: rgba(255, 255, 255, 0.5);
  }
  33% {
    background: #ff8326;
    color: #fff;
  }
  66% {
    background: transparent;
    color: rgba(255, 255, 255, 0.5);
  }
  100% {
    background: #ff8326;
    color: #fff;
  }
}

#wc-footer .record {
  height: 100%;
  width: 95px;
  cursor: pointer;
  float: left;
  border: none;
  background-color: transparent;
}

#wc-footer .join-audio:hover,
#wc-footer .mute:hover,
#wc-footer .invite:hover,
#wc-footer .record:hover,
#wc-footer .participants-mgt:hover,
#wc-footer .start-video:hover,
#wc-footer .stop-video:hover,
#wc-footer .about-video:hover,
#wc-footer .vga:hover,
#wc-footer .chat:hover,
#wc-footer .closedcaption:hover,
#wc-footer .raise-lower-hand:hover,
#wc-footer .footer-more-btn:hover {
  background: #424345;
}

#wc-footer .join-audio:active,
#wc-footer .mute:active,
#wc-footer .invite:active,
#wc-footer .record:active,
#wc-footer .participants-mgt:active,
#wc-footer .leave:active,
#wc-footer .start-video:active,
#wc-footer .stop-video:active,
#wc-footer .about-video:active,
#wc-footer .vga > button:active,
#wc-footer .chat:active,
#wc-footer .closedcaption:active,
#wc-footer .raise-lower-hand:active,
#wc-footer .footer-more-btn:active {
  opacity: 0.5;
}

#wc-footer .join-audio.active {
  opacity: 0.5;
}

#wc-footer .join-audio .img-layer {
  padding-top: 3.5px;
}

#wc-footer .pop-menu {
  display: inline-block;
  float: left;
  width: 20px;
  height: 100%;
  cursor: pointer;
}

#wc-footer .btn-toolbar {
  display: inline-block;
}

#wc-footer .pop-menu:hover {
  background: #424345;
}

#wc-footer .mute .img-layer {
  padding-top: 5.5px;
}

#wc-footer .start-video .img-layer {
  padding-top: 8.5px;
}

#wc-footer .stop-video .img-layer {
  padding-top: 5.5px;
}

#wc-footer .about-video .img-layer {
  padding-top: 10.5px;
}

#dialog-join .loading {
  background: url(../image/wc_sprites.png) no-repeat -578px -66px;
  width: 26px;
  height: 26px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  margin-top: -2px;
  animation: 1.5s linear 0s normal none infinite rotate;
  -webkit-animation: 1.5s linear 0s normal none infinite rotate;
}

#wc-container .loading {
  position: absolute;
  background: url(../image/wc_sprites.png) no-repeat -578px -66px;
  width: 26px;
  height: 26px;
  left: 13px;
  top: 13px;
  animation: 1.5s linear 0s normal none infinite rotate;
  -webkit-animation: 1.5s linear 0s normal none infinite rotate;
}

/*loading*/

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#wc-footer .img-layer span.img-sprite {
  background-image: url(../image/wc_sprites.png);
  background-repeat: no-repeat;
  background-size: 416px 384px;
  display: inline-block;
}

#wc-footer .img-layer span.img-audio {
  background-position: -35px -2px;
  width: 26px;
  height: 28px;
}

#wc-footer .img-layer span.img-mute {
  background-position: -73px -3px;
  width: 14px;
  height: 25px;
  margin-top: 1px;
}

#wc-footer .img-layer span.img-start-video {
  background-position: -239px -153px;
  width: 30px;
  height: 30px;
}

#wc-footer .pop-menu span {
  width: 13px;
  height: 17px;
  background-position: -281px -152px;
  margin-top: 10px;
}

#wc-footer .img-layer span.img-mute-animation {
  background-position: -73px -3px;
  width: 16px;
  height: 26px;
  animation: audio 1.5s steps(1) infinite;
  -moz-animation: audio 1.5s steps(1) infinite;
  -webkit-animation: audio 1.5s steps(1) infinite;
}

@keyframes audio {
  0% {
    background-position: -136px -67px;
  }
  20%,
  35% {
    background-position: -168px -67px;
  }
  50% {
    background-position: -200px -67px;
  }
  65% {
    background-position: -232px -67px;
  }
  80% {
    background-position: -296px -67px;
  }
  95% {
    background-position: -360px -67px;
  }
  100% {
    background-position: -200px -67px;
  }
}

@-moz-keyframes audio {
  0% {
    background-position: -136px -67px;
  }
  20%,
  35% {
    background-position: -168px -67px;
  }
  50% {
    background-position: -200px -67px;
  }
  65% {
    background-position: -232px -67px;
  }
  80% {
    background-position: -296px -67px;
  }
  95% {
    background-position: -360px -67px;
  }
  100% {
    background-position: -200px -67px;
  }
}

@-webkit-keyframes audio {
  0% {
    background-position: -136px -67px;
  }
  20%,
  35% {
    background-position: -168px -67px;
  }
  50% {
    background-position: -200px -67px;
  }
  65% {
    background-position: -232px -67px;
  }
  80% {
    background-position: -296px -67px;
  }
  95% {
    background-position: -360px -67px;
  }
  100% {
    background-position: -200px -67px;
  }
}

/*#wc-footer #wc-toolbar .join .hover, */

#wc-footer .img-layer span.img-unmute {
  background-position: -99px -2px;
  width: 26px;
  height: 26px;
}

#wc-footer .img-layer span.img-disallow-mic {
  background-position: -4px -184px;
  width: 26px;
  height: 30px;
  transform: translateY(-2px);
  -webkit-transform: translateY(-2px);
}

#wc-footer .img-layer span.img-disallow-camera {
  background-position: -311px -216px;
  width: 32px;
  height: 30px;
}

#wc-footer .img-layer span.img-stop-video {
  background-position: -192px -153px;
  width: 30px;
  height: 30px;
}

#wc-footer .img-layer span.img-invite {
  background-position: -129px -6px;
  width: 30px;
  height: 23px;
}

#wc-footer .img-layer span.img-chat {
  background-position: -129px -220px;
  width: 30px;
  height: 26px;
}

#wc-footer .img-layer span.img-mute-phone {
  background-position: -3px -67px;
  width: 26px;
  height: 26px;
}

#wc-footer .img-layer span.img-unmute-phone {
  background-position: -34px -67px;
  width: 29px;
  height: 27px;
}

#wc-footer .img-layer span.img-more {
  background-position: -357px -216px;
  width: 32px;
  height: 30px;
}

.icon-mute {
  display: inline-block;
  background: url(../image/wc_sprites.png) no-repeat -73px -3px;
  background-size: 416px 384px;
  width: 14px;
  height: 25px;
  margin: 13px 0;
}

.icon-unmute {
  display: inline-block;
  background: url(../image/wc_sprites.png) no-repeat -99px -3px;
  width: 26px;
  height: 26px;
  background-size: 416px 384px;
  margin: 13px 0;
}

#wc-footer .img-layer span.img-muteall {
  background-position: -193px 0;
  width: 30px;
  height: 31px;
}

#wc-footer .img-layer span.img-unmuteall
/*,
    #wc-footer #wc-toolbar .mute .hover */

 {
  background-position: -225px 0;
  width: 30px;
  height: 31px;
}

#wc-footer .img-layer span.img-recording {
  background-position: -260px -4px;
  width: 24px;
  height: 24px;
}

#wc-footer .img-layer span.img-stoprecording {
  background-position: -292px -4px;
  width: 24px;
  height: 24px;
}

#wc-footer .img-layer span.img-lock {
  background-position: -327px -4px;
  width: 18px;
  height: 24px;
}

#wc-footer .img-layer span.img-unlock {
  background-position: -360px -4px;
  width: 18px;
  height: 24px;
}

#wc-footer .img-layer span.img-hangup {
  background-position: -321px -43px;
  width: 30px;
  height: 10px;
}

#canvas-screen {
  width: 100%;
  height: 100%;
}

.sharing-indicator {
  position: absolute;
  width: 100%;
  font-size: 12px;
  height: 22px;
  color: #ffffff;
  z-index: 20;
}

.sharing-indicator > div {
  display: inline-block;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 0 0 4px 4px;
}

.s-full-screen,
.s-resize,
.s-remote-control {
  display: inline-block;
  padding: 0 10px;
  border: none;
  background: linear-gradient(to bottom, #4c4e50, #1e1f21);
  height: 22px;
  line-height: 22px;
  cursor: pointer;
}

.s-full-screen:hover,
.s-resize:hover,
.s-remote-control:hover {
  background: linear-gradient(to bottom, #26282a, #1d1f21);
}

.s-remote-control {
  border-radius: 0 0 4px 0;
}

.s-tip {
  float: left;
  display: inline-block;
  padding: 0 10px;
  height: 22px;
  line-height: 22px;
  color: #3a3c3e;
  background-image: linear-gradient(to bottom, #4bc800, #46bb00);
  border-radius: 0 0 0 4px;
  max-width: 480px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.s-tip-control {
  background: #ffc80f;
}

.s-full-screen .full {
  display: inline-block;
  background: url(../image/wc_sprites.png) no-repeat -321px -97px;
  background-size: 416px 384px;
  width: 13px;
  height: 13px;
  margin-right: 5px;
  vertical-align: middle;
}

.s-full-screen .full:hover {
  display: inline-block;
  background: url(../image/wc_sprites.png) no-repeat -337px -97px;
  background-size: 416px 384px;
  width: 13px;
  height: 13px;
  margin-right: 5px;
  vertical-align: middle;
}

.s-full-screen .no-full {
  display: inline-block;
  background: url(../image/wc_sprites.png) no-repeat -289px -97px;
  background-size: 416px 384px;
  width: 13px;
  height: 13px;
  margin-right: 5px;
  vertical-align: middle;
}

.s-full-screen .no-full:hover {
  display: inline-block;
  background: url(../image/wc_sprites.png) no-repeat -305px -97px;
  background-size: 416px 384px;
  width: 13px;
  height: 13px;
  margin-right: 5px;
  vertical-align: middle;
}

.sharing-outline {
  border: solid 3px #4bc800;
  z-index: 10;
}

/*************************dialog******************************/

.modal-dialog .tip {
  font-size: 14px;
  color: #333;
}

.modal-body {
  padding: 30px 30px 24px 30px !important;
  font-size: 13px;
  color: $black18;
}

.modal-body .form-group {
  margin-bottom: 6px;
}

.modal-footer {
  padding-right: 30px;
}

#reconnectConfDialog {
  font-size: 15px;
}

#reconnectConfDialog .error-code {
  font-size: 12px;
  color: #999999;
}

#reconnectConfDialog #errorcode {
  margin-left: 5px;
}

#reconnectConfDialog .modal-body .form-group {
  margin-bottom: 20px;
}

#dialInDialog ul li {
  list-style: none;
}

#dialInDialog .connected {
  margin-left: 24px;
  font-size: 13px;
}

#dialInDialog .presscode,
#dialInDialog .Participant-id {
  font-weight: 600;
  margin-left: 10px;
}

#dialInDialog .press-tip,
#dialInDialog .display-number {
  font-weight: 600;
}

#dialInDialog #dialog-tollnumber {
  margin-top: 7px;
  margin-left: -30px;
}

#dialInDialog .display-number {
  margin-right: 5px;
}

#dialInDialog .meeting-id {
  margin: 8px 0 8px 0;
}

.dialog-ctn {
  max-width: 1200px;
  margin: 0 auto;
}

/*************************@media 1280/1440/1920******************************/

@media screen and (max-width: 1280px) {
  #wc-container {
    min-height: 105px !important;
    max-height: 430px !important;
  }
  #wc-container .attendee-content {
    width: 960px;
  }
  #wc-header .list ul {
    max-height: 435px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px) {
  #wc-container {
    max-height: 428px;
  }
  #wc-container .attendee-content {
    width: 1155px;
  }
  .show-participants .attendee-content {
    width: 1000px !important;
  }
  #wc-header .list ul {
    max-height: 475px;
  }
}

@media screen and (min-width: 1440px) and (max-width: 1920px) {
  #wc-container {
    max-height: 648px;
  }
  #wc-container .attendee-content {
    max-width: 1345px;
  }
  #wc-header .list ul {
    max-height: 690px;
  }
}

#dialog-join .top,
#dialog-invite .top {
  height: 55px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

#dialog-invite .top {
  margin-bottom: 40px;
}

#dialog-join .title {
  display: inline-block;
  margin-bottom: 0px;
  height: 100%;
  font-size: 18px;
  font-weight: 300;
}

#dialog-join .tab {
  width: 30%;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  min-width: 100px;
}

#dialog-join .top .title:first-child + .tab {
  border-left: 1px solid rgba(0, 0, 0, 0.2);
}

#dialog-join .title:hover {
  cursor: pointer;
  background: linear-gradient(rgb(24, 24, 24), rgb(0, 0, 0)) rgb(0, 0, 0);
}

.top ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.top li {
  padding: 0;
  display: inline;
  letter-spacing: -4px;
  width: 20%;
}

#dialog-join .top .left-img {
  display: inline-block;
  background: url(../image/wc_sprites.png) no-repeat;
  background-size: 416px 384px;
  background-position: -104px -40px;
  width: 16px;
  height: 16px;
  position: relative;
  top: 2px;
  right: 10px;
}

#dialog-invite .top .left-img {
  display: inline-block;
  background: url(../image/wc_sprites.png) no-repeat;
  background-size: 416px 384px;
  background-position: -72px -40px;
  width: 20px;
  height: 15px;
  position: relative;
  top: 2px;
  right: 10px;
}

#dialog-join .top .center-img {
  display: inline-block;
  background: url(../image/wc_sprites.png) no-repeat;
  background-size: 416px 384px;
  background-position: -167px -41px;
  width: 17px;
  height: 14px;
  position: relative;
  top: 1px;
  right: 10px;
}

.top .right-img {
  display: inline-block;
  background: url(../image/wc_sprites.png) no-repeat;
  background-size: 416px 384px;
  background-position: -104px -38px;
  width: 16px;
  height: 19px;
  position: relative;
  top: 2px;
  right: 6px;
}

.triangle1,
.triangle2,
.triangle3 {
  margin-top: -7px;
  text-align: center;
}

.triangle-icon {
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-top: 6px solid #000;
  border-right: 12px solid transparent;
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop,
  .triangle1,
  *::-ms-backdrop,
  .triangle2,
  *::-ms-backdrop,
  .triangle3 {
    margin-top: 36px;
  }
  /* IE11 */
}

.top .close {
  width: 0;
  float: right;
  opacity: 1;
}

.close-jd {
  background: url(../image/wc_sprites.png) no-repeat -400px -32px;
  background-size: 416px 384px;
  width: 16px;
  height: 16px;
  float: right;
  margin: 20px 10px;
  padding: 0 10px 0 4px;
}

/* react modal*/

.ReactModal__Overlay {
  -webkit-perspective: 600;
  perspective: 600;
  opacity: 0;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 99;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transition: opacity 150ms ease-out;
}

.ReactModal__Content {
  -webkit-transform: scale(0.5) rotateX(-30deg);
  transform: scale(0.5) rotateX(-30deg);
}

.ReactModal__Content--after-open {
  -webkit-transform: scale(1) rotateX(0deg);
  transform: scale(1) rotateX(0deg);
  transition: all 150ms ease-in;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content--before-close {
  -webkit-transform: scale(0.5) rotateX(30deg);
  transform: scale(0.5) rotateX(30deg);
  transition: all 150ms ease-in;
}

.ReactModal__Content {
  border: none;
  background-color: transparent;
}

.showParticipants {
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.2);
}

// .participant-name {
//     max-width: 150px;
// }

// .waiting-participant-name {
//     max-width: 215px;
// }

// .hover-participant-name {
//     max-width: 110px;
// }

// .participant-name,
// .waiting-participant-name {
//     overflow: hidden;
//     text-overflow: ellipsis;
//     white-space: nowrap;
//     display: inline-block;
//     vertical-align: middle;
// }

.find-tip {
  display: inline-block;
  position: absolute;
  left: 0;
  bottom: -40px;
  font-size: 13px;
}

.toll-numbers {
  width: 440px;
}

.toll-numbers .diail-location-tip {
  margin-bottom: 8px;
}

.toll-numbers .Select {
  position: absolute;
  width: 130px;
  font-size: 13px;
  display: inline-block;
  margin-left: 20px;
}

.toll-numbers .countryCodeSelect-select-section {
  width: 150px;
  float: left;
}

.country-code-select input[type='text'][value=''] {
  outline: 0 !important;
}

.toll-numbers .tollnumber-section {
  float: left;
  width: 260px;
}

.toll-numbers #tollnumber {
  margin-left: 20px;
}

.zoom-call-info {
  margin-top: 12px;
  clear: both;
}
#callout-form,
#callout-status {
  margin-top: 50px;
}

#dialog-join ol {
  padding-left: 50px;
  position: relative;
}

#joinPhoneNumber {
  height: 36px;
  width: 45%;
  display: inline-block;
  float: right;
}

.pc-tip {
  font-size: 13px;
  color: #f5a623;
}

.modal-content {
  border-radius: 4px;
}

.modal-header {
  padding: 12px 16px 10px;
}

.modal-header h3 {
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  color: $black18;
}

.cursor-x {
  cursor: ew-resize;
}

.cursor-draggable {
  cursor: move;
}

// .invite-tip {
//     font-size: 13px;
//     text-align: center;
// }

.url-tip {
  font-size: 13px;
  text-align: center;
  margin: 25px 0;
}

.email-section {
  margin-top: 40px;
}

.email-section a {
  text-decoration: none;
}

.d-email,
.g-email,
.y-email {
  display: inline-block;
  width: 33%;
  text-align: center;
  font-size: 13px;
}

.d-email .icon {
  background-image: url(../image/default_email.png);
  width: 83px;
  height: 61px;
  display: block;
  background-size: 83px 61px;
  margin: 0 auto;
  cursor: pointer;
}

.g-email .icon {
  background-image: url(../image/gmail.png);
  width: 82px;
  height: 60px;
  display: block;
  background-size: 82px 60px;
  margin: 0 auto;
  cursor: pointer;
}

.y-email .icon {
  background-image: url(../image/yahoo_mail.png);
  width: 84px;
  height: 61px;
  display: block;
  background-size: 84px 61px;
  margin: 0 auto;
  cursor: pointer;
}

.g-email .icon:active,
.y-email .icon:active,
.d-email .icon:active {
  opacity: 0.8;
}

.copy-btn {
  // text-align: center;
}

.copy-btn button {
  height: 24px;
  border-radius: 3px;
  border: solid 1px rgba(255, 255, 255, 0.4);
  padding: 0 24px;
  font-weight: 500;
  margin: 0 3px;
  display: inline-block;
  font-size: 13px;
  background-color: rgba(255, 255, 255, 0.1);
  min-width: 150px;
}

.s-tip span:first-child {
  margin-right: 3px;
}

.sharing-mute-icon {
  display: inline-block;
  background: url(../image/wc_sprites.png) no-repeat -385px -50px;
  background-size: 416px 384px;
  width: 13px;
  height: 12px;
  margin: 0 5px;
  vertical-align: top;
  margin-top: 5px;
}

.sharing-record-icon {
  display: inline-block;
  background: url(../image/wc_sprites.png) no-repeat -40px -354px;
  background-size: 416px 384px;
  width: 16px;
  height: 12px;
  margin: 4px 4px 0 4px;
  transform: scale(1.2);
  animation: rec-cloud-animation2 1.5s steps(1) infinite;
  vertical-align: top;
}

.sharing-record-icon--local {
  width: 14px;
  height: 14px;
  background-position: -169.2px -353px;
  animation: rec-local-animation 1.5s steps(1) infinite;
}
/* put on hold*/

.bhold {
  height: 100%;
  background-color: #fff;
  color: #747487;
  display: flex;
}
.wr-meeting-message {
  flex-basis: 400px;
  flex-shrink: 0;
  padding: 0 2px;
  border-left: 1px solid #ededf4;
}

.wr-icon__report-flag {
  margin-right: 8px;
  vertical-align: text-top;
}

.wr-divider {
  margin-left: 13px;
  margin-right: 16px;
}

// .bhold-msg {
//     display: inline-block;
//     font-size: 18px;
//     margin-left: 5px;
//     color: #232333;
// }

// .bhold-meeting-topic {
//     font-size: 22px;
//     margin-top: 37px;
//     /*315-254-24*/

// }

// .bhold-msg-adjust {
//     position: relative;
//     top: 64px;
// }

// .circle-sm-loading {
//     display: inline-block;
//     width: 26px;
//     height: 26px;
//     background: url(../image/wc_sprites.png) no-repeat -227px -35px;
//     background-size:  416px 384px;
//     -webkit-animation: circle-loading 2s infinite linear;
//     animation: circle-loading 2s infinite linear;
//     margin: 0 auto;
// }

@-moz-keyframes circle-loading {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(359deg);
  }
}

@-webkit-keyframes circle-loading {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
  }
}

@keyframes circle-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.title-adjust {
  margin-bottom: 10px;
}

// move to webinar-participants-tab.scss
// .participants-tab {
//   width: 95%;
//   height: 24px;
//   border-radius: 5px;
//   border: solid 1px #64a0e1;
//   margin: 0 auto 10px;
//   background-color: rgb(255, 255, 255);
//   line-height: 1.6;
//   cursor: pointer;
//   user-select: none;
// }

.pane-tab {
  width: 50%;
  height: 22px;
  text-align: center;
  float: left;
}

.att-tab {
  width: 50%;
  height: 22px;
  text-align: center;
}

.text-ellipsis,
.pane-tab,
.att-tab {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


// .waitingroom-title,
.participants-title {
  background-color: #fff;
  font-size: 13px;
  font-weight: bold;
  padding: 10px 0 10px 8px;
}

.tesla-ui .participants-title {
  background-color: #242424;
  font-size: 22px;
  padding: 20px;
}

// .opacity6 {
//     opacity: 0.6;
// }

.lnk-remove {
  padding-left: 10px;
}

.attendees-waitingroom .btn-toolbar-opacity {
  opacity: 1;
}

// .hover-list-item {
//     max-width: 170px;
// }

.waiting-room-name {
  color: #d4c7c7;
}

// .not-hover-list-item {
//     max-width: 160px;
// }

.item-pos {
  position: relative;
}

.participants-li .dropdown {
  float: right;
}

.participants-me .dropdown-menu,
.participants-ul .dropdown-menu,
.bottom .dropdown-menu {
  font-size: 12px;
}

.avatar-more {
  position: absolute;
  top: 4px;
}

.avatar-more .dropdown-menu {
  font-size: 12px;
  background-color: rgba(17, 17, 17, 0.9);
  color: blanchedalmond;
  top: 25px;
  right: 4px;
  text-align: left;
}

.avatar-more .dropdown-menu > a {
  color: white;
}

.avatar-more .dropdown-menu > a:hover,
.avatar-more .dropdown-menu > a:focus {
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.1);
}

.rename-label,
.i-ok-margin {
  margin-right: 10px;
}

.i-margin {
  margin-right: 22px;
}

.bottom .btn {
  margin: 0 20px;
}

.float-left {
  float: left;
}

.makehost-modal-i {
  display: inline-block;
  font-size: 25px;
  color: #2da5ff;
  right: 5px;
  vertical-align: middle;
}

.makehost-modal-body {
  position: relative;
  display: inline-block;
  width: 90%;
  margin-left: 10px;
  vertical-align: middle;
}

.makehost-modal-header {
  border-bottom-width: 0;
}
.broadcast-wrap {
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 10px;
  z-index: 1;
}
.broadcast-wrap-offset {
  top: 64px;
}

.broadcast-layer {
  display: flex;
  min-width: 600px;
  padding: 0 12px;
  box-sizing: border-box;
  align-items: center;
  height: 50px;
  background-color: #fcf6ed;
  border-radius: 8px;
}

.broadcast-txt {
  flex-grow: 1;
  font-weight: normal;
  color: #775111;
  white-space: nowrap;
  margin-right: 10px;
}

.broadcast-btn {
  flex-basis: 98px;
  height: 32px;
  background: #fcf6ed !important;
  color: #775111 !important;
  border: 1px solid #775111 !important;
  border-radius: 8px;
}

.broadcast-layer2 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  width: 360px;
  height: 40px;
  padding: 0 12px;
  background-color: #e4f7eb;
  border-radius: 8px;
  color: #1c7e41;
}

.head-meeting-topic {
  display: inline;
  height: 18px;
  font-size: 13px;
  font-weight: 600;
  color: #4a4a4a;
}

.rs-tab {
  background: #1e2122;
  height: 40px;
  border: solid 1px #1e2122;
  border-radius: 3px;
  width: 80%;
  margin: 0 auto;
}

.rs-tab-active {
  display: inline-block;
  background-color: #414244;
  height: 100%;
  width: 49%;
  border: none;
  border-radius: 3px;
  margin: 0 1px;
}

.rs-tab-inactive {
  display: inline-block;
  background-color: #1e2122;
  color: #878787;
  height: 100%;
  width: 49%;
  border: none;
  border-radius: 3px;
  margin: 0 1px;
}

.ts-tab-tip {
  font-size: 12px;
  font-style: italic;
  color: #878787;
}

.rs-ip-container {
  width: 60%;
  margin: 40px auto 20px;
}

.rs-ip-container input {
  width: 100% !important;
}

.rs-type-container {
  text-align: center;
}

.rs-type-container input[type='radio'] {
  margin-right: 10px;
  position: relative;
  top: 2px;
}

.rs-type-container label:first-child {
  margin-right: 15px;
}

.rs-type-container label:last-child {
  margin-left: 15px;
}

.rs-type-container label {
  cursor: pointer;
}

// .rs-dialin {
//     margin-top: 12px;
// }

// .rs-dialin ol {
//     padding-left: 0px;
// }

// .rs-dialin li {
//     margin-bottom: 15px;
// }

// .rs-dial-title {
//     width: 10%;
//     display: inline-block;
//     margin-bottom: 84px;
// }

.rs-dial-content {
  width: 85%;
  display: inline-block;
  height: 100px;
  border-radius: 3px;
  background: #2a2b2e;
  padding: 9px 10px 9px 10px;
  float: right;
  overflow: auto;
}

// .rs-dial-hr {
//     text-align: center;
//     color: #878787;
//     margin-top: -10px;
//     margin-bottom: 10px;
//     font-size: 12px;
// }

// .rs-dial-hr hr {
//     width: 30px;
//     display: inline-block;
//     margin: 0 5px;
//     vertical-align: middle;
//     border-color: #878787
// }

// .rs-dialin input {
//     width: 30px;
//     height: 30px;
//     background: #1e2122;
//     border: #1e2122;
//     border-radius: 3px;
//     margin: auto 2px;
//     text-align: center;
// }
.invite-rs-pairicode-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.invite-rs-pairicode {
  min-width: 80px;
  height: 30px;
  color: #fff;
  font-size: 15px;
  margin-top: 0px;
  margin-left: 10px;
  vertical-align: top;
  padding-top: 4px;
}

.scrollbar-style::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #2a2b2e;
}

.scrollbar-style::-webkit-scrollbar {
  width: 10px;
  background-color: #2a2b2e;
}

.scrollbar-style::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

.dial-ip-item {
  margin-bottom: 5px;
}

span.dial-ip-tip {
  color: #f5a623;
  margin-right: 15px;
}

span.dial-country-tip {
  color: #878787;
}

.invite-rs-status {
  color: rgb(247, 167, 0);
  position: absolute;
  top: 50px;
  left: 310px;
  width: 80px;
  margin-top: 4px;
}

.otl-tip {
  margin-top: 40px;
  text-align: center;
}

.otl-content {
  width: 100%;
  border-radius: 3px;
  background: #2a2b2e;
  padding: 10px 10px 10px 20px;
  overflow: auto;
  margin: 15px auto 0;
  height: 50%;
  color: #f5a623;
  border: 0px;
}

// .bo-bottom-btn-container {
//   display: flex;
//   flex-wrap: nowrap;
//   justify-content: space-around;
// }

// .bo-bottom-btn-container button {
//   float: none !important;
//   /*margin: 0 !important;*/
// }

.using-audio-tip {
  color: #f5a623;
  text-align: center;
  margin-top: 20px;
}

/* New css for speaker view*/

#sv-swiper-center {
  position: relative;
  margin: 0 auto;
  width: 1064px;
  height: 100%;
  text-align: center;
}

#sv-swiper-center-container {
  height: 100%;
  padding: 10px 0;
  display: inline-block;
  width: 960px;
  overflow: visible;
}

@media screen and (max-width: 1350px) {
  #sv-swiper-center {
    width: 904px;
  }
}

@media screen and (max-width: 1180px) {
  #sv-swiper-center {
    width: 744px;
  }
}

@media screen and (max-width: 1010px) {
  #sv-swiper-center {
    width: 584px;
  }
}

@media screen and (max-width: 840px) {
  #sv-swiper-center {
    width: 424px;
  }
}

@media screen and (max-width: 670px) {
  #sv-swiper-center {
    width: 264px;
  }
}

#sv-attendees-swiper {
  width: 100%;
  height: 110px;
  background: #000;
}

#sv-active-speaker-view {
  width: 100%;
  height: 100%;
  text-align: center;
  background: #000;
  position: relative;
}

#sv-active-vide {
  background: green;
  height: 100%;
  width: 80%;
}

.sv-swiper-left,
.sv-swiper-right {
  display: inline-block;
  cursor: pointer;
}

.sv-attendee-template {
  height: 100%;
  display: inline-block;
  background: white;
  margin-bottom: 10px;
  width: 160px !important;
}

.swiper-button-prev,
.swiper-button-next {
  top: 0;
  left: 0;
  background-image: none !important;
  margin-top: 35px;
  border-style: solid;
  position: relative;
  height: 0;
  width: 0;
  box-shadow: 0 0 0 28px #000;
}

.swiper-button-prev:before,
.swiper-button-prev:after,
.swiper-button-next:before,
.swiper-button-next:after {
  content: '';
  position: absolute;
  background: #000;
  left: 0;
  top: 0;
  cursor: initial;
}

.swiper-button-prev:after {
  left: 2px;
}

.swiper-button-next:before {
  left: -2px;
}

.swiper-button-prev:before {
  padding: 50px 1000px;
  transform: translate(-100%, -50%);
}

.swiper-button-prev:after {
  padding: 50px 15px;
  transform: translate(20px, -50%);
}

.swiper-button-next:before {
  padding: 50px 10px;
  transform: translate(-50px, -50%);
}

.swiper-button-next:after {
  padding: 50px 1000px;
  transform: translateY(-50%);
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 0;
}

.swiper-button-next {
  float: right;
  border-width: 22px 0 22px 22px;
  border-color: #000 transparent #000 #1cb8ff;
}

.swiper-button-next:focus,
.swiper-button-prev:focus {
  outline: none;
}

.swiper-button-prev {
  float: left;
  border-width: 22px 22px 22px 0;
  border-color: #000 #1cb8ff #000 transparent;
}

.sv-swiper-attendee-avatar {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: #222222;
  border: solid 2px #000;
}

.sv-attendee-template .has-asn {
  border-color: #48bf00;
}

.sv-swiper-attendee-avatar img {
  height: 100%;
}

.sv-swiper-attendee-avatar .sv-name {
  position: absolute;
  max-width: 120px;
  white-space: nowrap;
  left: 0;
  bottom: 0;
  color: #fff;
  background: rgba(0, 0, 0, 0.7);
  font-size: 12px;
  padding: 1px 8px;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
}

.sv-swiper-attendee-avatar .muted-icon {
  padding-left: 5px;
}

.sv-swiper-attendee-avatar .muted-icon:before {
  content: '';
  width: 10px;
  height: 10px;
  background: url(../image/wc_sprites.png) no-repeat -52px -192px;
  background-size: 416px 384px;
  padding-left: 15px;
  margin-right: 5px;
}

.sv-btn-group {
  position: absolute;
  overflow: hidden;
  right: 0;
  top: 0;
}

.sv-btn {
  float: right;
  position: relative;
  margin: 5px 5px 0 0;
  padding: 0 5px;
  border-radius: 2px;
  background: #2d8cff;
  height: 20px;
  line-height: 20px;
  text-align: center;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
}

.sv-btn-group .more {
  width: 20px;
  height: 20px;
  padding: 0;
}

.sv-btn-group .more:before {
  content: '';
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: -5px 0 0 0 #fff, 5px 0 0 0 #fff;
}

.sv-btn:hover {
  background: #2da5ff;
}

.sv-btn:active {
  background: #287de5;
}

.suspension-window {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
}

.suspension-no-drag {
  transition: transform 0.3s;
  -webkit-transition: transform 0.3s;
}

.suspension-header {
  background: rgba(32, 32, 32, 1);
  height: 28px;
  line-height: 28px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  position: relative;
}

.suspension-header-btn {
  display: inline-block;
}

.suspension-header .type1,
.suspension-header .type2,
.suspension-header .type3,
.suspension-header .type4 {
  width: 28px;
  position: relative;
  height: 100%;
  cursor: pointer;
}

.suspension-header .type1 {
  border-top-left-radius: 3px;
  -webkit-border-top-left-radius: 3px;
}

.suspension-header .type1:hover,
.suspension-header .type2:hover,
.suspension-header .type3:hover,
.suspension-header .type4:hover {
  background: linear-gradient(#545454, 15%, #1c1e20);
}

.suspension-header .type1::before,
.suspension-header .type2::before,
.suspension-header .type3::before,
.suspension-header .type4::before {
  background: url(../image/wc_sprites.png) no-repeat;
  background-size: 416px 384px;
  position: absolute;
}

.suspension-header .type1::before {
  content: '';
  width: 14px;
  height: 5px;
  background-position: -367px -172px;
  margin-top: 16px;
  margin-left: 8px;
}

.suspension-header .active1::before {
  background-position: -309px -153px;
}

.suspension-header .type2::before {
  content: '';
  width: 16px;
  height: 12px;
  background-position: -347px -167.5px;
  margin-top: 10px;
  margin-left: 6px;
}

.suspension-header .active2::before {
  background-position: -326px -152px;
}

.suspension-header .type3::before {
  content: '';
  width: 18px;
  height: 15px;
  background-position: -326px -163px;
  margin-left: 4px;
  margin-top: 6px;
}

.suspension-header .active3::before {
  background-position: -348px -152px;
}

.suspension-header .type4::before {
  content: '';
  width: 18px;
  height: 14px;
  background-position: -307px -163.5px;
  margin-top: 7px;
  margin-left: 4px;
}

.suspension-header .active4::before {
  background-position: -367px -154px;
}

.suspension-content {
  overflow: hidden;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
}

.suspension-content1 {
  width: 200px;
  padding: 10px;
  background: rgba(32, 32, 32, 0.8);
  color: #fff;
  font-size: 12px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.8);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: move;
}

.suspension-canvas-hide {
  display: none;
}

.suspension-content2 {
  width: 240px;
  height: 135px;
  background: rgba(32, 32, 32, 1);
  color: #fff;
  text-align: center;
  cursor: move;
  position: relative;
}

.suspension-content2.suspension-fixed-height {
  height: 135px;
}

.suspension-content3 {
  width: 480px;
  height: 270px;
  background: rgba(32, 32, 32, 0.8);
  color: #fff;
  text-align: center;
  cursor: move;
  position: relative;
}

.suspension-content3.suspension-fixed-height {
  height: 270px;
}

.suspension-fixed-height .suspension-canvas-layer {
  height: 100%;
}

.suspension-canvas-layer {
  overflow: hidden;
  width: 100%;
}

.suspension-img-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.suspension-canvas {
  width: 100%;
}

.suspension-user-name {
  position: absolute;
  max-width: 50%;
  white-space: nowrap;
  left: 0;
  bottom: 0;
  color: #fff;
  background: rgba(0, 0, 0, 0.8);
  font-size: 12px;
  padding: 4px 6px;
  text-align: left;
  text-overflow: ellipsis;
  border-top-right-radius: 7px;
  border-bottom-left-radius: 7px;
  -webkit-border-top-right-radius: 7px;
  -webkit-border-bottom-left-radius: 7px;
  overflow: hidden;
}

.sharing-me {
  width: 33.3%;
  height: 33.3%;
  position: absolute;
  right: 4px;
  bottom: 4px;
  overflow: hidden;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
}

.sharing-me-spotlight {
  width: 100%;
  height: 100%;
  right: 0;
  bottom: 0;
}

.nosupport-info {
  text-align: center;
  width: 400px;
  margin: 35px auto 0;
}

.active-user-name {
  position: absolute;
  max-width: 40%;
  left: 0;
  bottom: 0;
  height: 25px;
  white-space: nowrap;
  color: #fff;
  background: rgba(0, 0, 0, 0.7);
  font-size: 12px;
  padding: 3px 8px;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
}

.active-avatar {
  position: absolute;
  max-width: 400px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.attendee-avatar-name {
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 86px;
  padding: 0 10px;
  font-weight: bold;
}

.active-avatar-name {
  color: #fff;
  font-size: 50px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 95%;
}

.active-main {
  position: relative;
  margin: 0 auto;
}

.btn-toolbar-nowrap {
  white-space: nowrap;
}

.audio_menu_disallow {
  font-size: 12px;
  color: #999;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 3px;
  margin: 7px 0;
  padding: 5px 16px;
}

.audio_menu_disallow a {
  color: #999;
  text-decoration: underline;
}

#wc-loading {
  width: 100%;
  height: 100%;
  background: #222;
  position: relative;
  text-align: center;
  z-index: 1;
}

// .loading-main {
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     -webkit-transform: translate(-50%, -50%);
//     -moz-transform: translate(-50%, -50%);
//     -ms-transform: translate(-50%, -50%);
//     -o-transform: translate(-50%, -50%);
//     transform: translate(-50%, -50%);
// }

// .participants-footer {
//     position: absolute;
//     bottom: 0;
//     width: 100%;
// }

// .participants-footer .bottom {
//     padding: 10px 0;
//     display: flex;
//     justify-content: center;
// }

// .chat-participant-header {
//     position: relative;
//     text-align: center;
//     font-size: 14px;
//     font-weight: bold;
//     border-top: 1px solid #eee;
//     padding: 10px;
// }

// .chat-participant-header>.dropdown {
//     position: absolute;
//     left: 0;
//     top: 0;
// }

// .chat-participant-header .chat-pop-btn {
//     position: absolute;
//     padding: 0;
//     border: none;
//     width: 16px;
//     height: 16px;
//     left: 13px;
//     top: 13px;
//     cursor: pointer;
//     background: transparent;
// }

// .chat-participant-header .chat-pop-btn:before {
//     content: '';
//     width: 16px;
//     height: 16px;
//     position: absolute;
//     left: 0;
//     top: 0;
//     background-image: url(../image/wc_sprites.png);
//     background-repeat: no-repeat;
//     background-size: 416px 384px;
//     background-position: -260px -214px;
// }

// .participant-pop-btn {
//     position: absolute;
//     padding: 0;
//     border: none;
//     width: 16px;
//     height: 16px;
//     left: 13px;
//     top: 6px;
//     cursor: pointer;
//     background: transparent;
// }

// .participant-pop-btn:before {
//     content: '';
//     width: 16px;
//     height: 16px;
//     position: absolute;
//     left: 0;
//     top: 0;
//     background-image: url(../image/wc_sprites.png);
//     background-repeat: no-repeat;
//     background-size: 416px 384px;
//     background-position: -260px -214px;
// }

// .participant-pop-btn+ul.dropdown-menu {
//     left: 11px;
//     top: 26px;
//     font-size: 12px;
//     min-width: 100px;
//     text-align: left;
// }

// .drop-up+ul.dropdown-menu {
//     transform: translateY(-100%);
//     -webkit-transform: translateY(-100%);
//     top: 9px;
// }

// .participant-pop-btn+ul.dropdown-menu>li>a {
// // .chat-pop-btn+ul.dropdown-menu>li>a {
//     padding: 3px 15px 3px 30px;
// }

// .participant-pop-btn+ul.dropdown-menu>li>a:before {
// // .chat-pop-btn+ul.dropdown-menu>li>a:before {
//     content: '';
//     background: url(../image/wc_sprites.png) no-repeat;
//     background-size: 416px 384px;
//     position: absolute;
//     width: 15px;
//     height: 15px;
//     left: 8px;
// }

// .participant-pop-btn+ul.dropdown-menu>li.chat-pop-close>a:before {
// // .chat-pop-btn+ul.dropdown-menu>li.chat-pop-close>a:before {
//     background-position: -228px -215px;
// }

// .participant-pop-btn+ul.dropdown-menu>li.chat-pop-minimize>a:before {
// // .chat-pop-btn+ul.dropdown-menu>li.chat-pop-minimize>a:before {
//     background-position: -244px -213px;
// }

// .participant-pop-btn+ul.dropdown-menu>li.chat-pop-expand>a:before {
// // .chat-pop-btn+ul.dropdown-menu>li.chat-pop-expand>a:before {
//     background-position: -276px -215px;
// }

// .participant-pop-btn+ul.dropdown-menu>li.chat-pop-out>a:before {
// // .chat-pop-btn+ul.dropdown-menu>li.chat-pop-out>a:before {
//     background-position: -213px -215px;
// }

// .chat-content {
//     padding-left: 10px;
// }

// .chat-msg {
//     font-size: 13px;
//     border: none;
//     background: transparent;
//     resize: none;
//     width: 100%;
//     padding: 0;
//     overflow: hidden;
// }

// .chat-msg:focus {
//     outline: none;
// }

// .chat-input {
//     position: relative;
// }

// .unread-msg {
//     position: absolute;
//     left: 270px;
//     bottom: 120px;
//     font-size: 12px;
//     border-radius: 3px;
//     background: $red5;
//     padding: 5px 10px;
//     color: #fff;
//     cursor: pointer;
// }

// .chat-control {
//     font-size: 12px;
//     padding: 0 10px;
//     min-width: auto;
//     height: 21px;
//     /* margin: 10px 0 5px; */
// }

// .chat-control-to {
//     padding: 2px 0;
//     margin-right: 5px;
// }

// .chat-menu+ul {
//     top: auto;
//     bottom: 25px;
//     font-size: 12px;
//     -webkit-box-shadow: 0 2px 12px rgba(0, 0, 0, .175);
//     box-shadow: 0 2px 12px rgba(0, 0, 0, .175);
// }

// .chat-menu+ul li>a {
//     display: block;
//     padding: 3px 20px 3px 25px;
//     clear: both;
//     font-weight: normal;
//     line-height: 1.42857143;
//     color: #333;
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
// }

// .chat-menu+ul li>a:hover,
// .chat-menu+ul li>a:focus {
//     color: #262626;
//     text-decoration: none;
//     background-color: #f5f5f5;
// }

// .chat-menu+ul li {
//     position: relative;
// }

// .chat-menu+ul li.selected>a:before {
//     content: '';
//     width: 11px;
//     height: 5px;
//     position: absolute;
//     left: 9px;
//     top: 8px;
//     transform: rotate(-45deg);
//     -webkit-transform: rotate(-45deg);
//     border-left: 1px solid #555;
//     border-bottom: 1px solid #555;
// }

// .chat-control .chat-more-menu+ul {
//     min-width: 200px;
// }

// .chat-control>span {
//     display: inline-block;
//     vertical-align: top;
//     padding: 2px 0;
// }

.chat-privately {
  margin-left: 5px;
  color: red;
  word-break: keep-all;
  white-space: nowrap;
}

// .warning-in-chat {
//     padding: 10px 15px;
//     font-size: 13px;
//     text-align: left;
//     background: #fff6d9;
//     color: #826919;
// }

// .not-receiver {
//     width: 100%;
//     position: absolute;
//     top: 0;
//     transform: translateY(-100%);
//     -webkit-transform: translateY(-100%);
// }

// .chat-menu .divider {
//     margin: 6px 0;
// }

// .chat-control-receiver {
//     position: relative;
//     margin-left: 5px;
//     background: #518cdb !important;
//     color: #fff !important;
//     padding: 2px 15px;
//     border-radius: 10.5px;
//     font-size: 12px;
//     cursor: pointer;
//     border: none;
//     max-width: 181px;
//     overflow: hidden;
//     text-overflow: ellipsis;
// }

// .chat-control-receiver:focus {
//     -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
//     box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
// }

//.chat-control-receiver+.dropdown-menu {}

// .chat-control-receiver:hover,
// .chat-control-receiver:focus {
//     background: #518cdb;
//     border: none;
//     color: #fff;
// }

// .chat-control-only {
//     display: inline-block;
//     padding: 2px 0;
//     max-width: 120px;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     white-space: nowrap;
// }

// .chat-control-more {
//     position: relative;
//     padding: 0 10px;
//     font-size: 12px;
//     border: 1px solid rgba(0, 0, 0, .2);
//     border-radius: 2px;
//     -webkit-border-radius: 2px;
//     height: 21px;
//     line-height: 20px;
//     cursor: pointer;
// }

// .chat-info {
//     font-size: 12px;
//     padding: 10px 0;
//     word-wrap: break-word;
// }

// .chat-info-header {
//     color: rgba(0, 0, 0, .4);
// }

// .chat-info-header>.can_select {
//     cursor: pointer;
//     color: #2DA5FF;
// }

// .chat-info-header>.can_select:hover,
// .chat-info-header>.can_select:focus {
//     text-decoration: underline;
// }

// .chat-info-header>.multi-chat:before {
//     content: ' , ';
// }

// .chat-info-header span {
//     overflow: hidden;
//     text-overflow: ellipsis;
//     display: inline-block;
//     white-space: nowrap;
//     vertical-align: top;
// }

// #chat-textarea {
//     width: 100%;
//     background: #FFFFFF;
//     border: none;
//     padding: 0px 10px;
//     font-size: 13px;
//     resize: none;
//     height: 85px;
// }

// textarea::placeholder {
//     color: #747487;
// }

// #chat-textarea::-webkit-input-placeholder {
//     color: #747487;
// }
textarea::placeholder {
  color: #747487;
}

#chat-textarea::-webkit-input-placeholder {
  color: #747487;
}

// #chat-textarea:focus {
//     border: none;
// }

.loading-img {
  width: 64px;
  -webkit-animation: wc-spin infinite 1.5s linear;
  -o-animation: wc-spin infinite 1.5s linear;
  animation: wc-spin infinite 1.5s linear;
}

@-webkit-keyframes wc-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes wc-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-ms-keyframes wc-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes wc-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes wc-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.join-meeting {
  font-size: 24px;
  color: #fff;
  margin: 12px auto 14px;
  padding-bottom: 25px;
}

.scroll-text {
  font-size: 14px;
  bottom: 0;
  width: 100%;
  text-align: center;
  white-space: nowrap;
  position: absolute;
  color: #8a8a9e;
}

// .disallow-mic-menu {
//     background: rgba(36, 39, 42, .9);
//     box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .26);
//     padding: 10px 12px;
//     font-size: 13px;
//     color: #bfbfbf;
//     text-align: left;
//     // position: absolute;
//     line-height: 18px;
//     left: 1110px;
//     bottom: 62px;
//     z-index: 9999;
//     border-radius: 3px;
//     max-width: 400px;
// }

// .disallow-mic-menu a {
//     color: #bfbfbf;
//     text-decoration: underline;
// }

.disallow-mic-info {
  text-align: center;
  padding: 20px 0;
  margin: 0 auto;
  color: #ff7800;
  font-size: 13px;
}

.disallow-mic-info a {
  text-decoration: underline;
}

.no-support-video {
  position: absolute;
  right: 10px;
  top: 10px;
  text-align: left;
  width: 300px;
  font-size: 13px;
  color: #826919;
  line-height: 18px;
  background: #fff6d9;
  padding: 10px 15px;
  border-radius: 3px;
  z-index: 9999;
}

.suspension-active-name {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 90%;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.common-window {
  position: relative;
  border: 1px solid #aaa;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  background-color: white;
}

.window-header {
  padding: 10px 20px 10px 8px;
  background: #ffffff;
  font-size: 14px;
  color: #333;
  border-radius: 8px 8px 0 0;
  .window-icon {
    float: initial;
  }
  @include flex-row-center
}

.window-content-bottom {
  border-radius: 0 0 8px 8px;
}

.window-icon {
  background-image: url(../image/wc_sprites.png);
  background-repeat: no-repeat;
  background-size: 416px 384px;
  background-color: transparent;
  width: 14px;
  height: 14px;
  float: right;
  cursor: pointer;
  border: none;
  padding: 0;
  margin: 0;
  outline: 2px solid transparent;
}

.window-icon:hover {
  background-color: #eee;
  outline: 2px solid #eee;
}

.window-icon:focus {
  outline: -webkit-focus-ring-color auto 5px;
}

.window-close {
  background-position: -385px -33px;
}

.window-merge {
  background-position: -214px -230px;
  margin-right: 12px;
}
.window-merge-disabled {
  background-position: -214px -230px;
  margin-right: 12px;
  opacity: 0.5;
}

.zoom-logo {
  background: url(../image/wc_sprites.png) no-repeat -168px -154px;
  width: 16px;
  height: 16px;
  background-size: 416px 384px;
  margin-right: 6px;
}

@-webkit-keyframes scroll-in-out {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  17% {
    transform: translateY(0);
    opacity: 1;
  }
  83% {
    transform: translateY(0);
    opacity: 1;
  }
  99% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
}

@keyframes scroll-in-out {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  17% {
    transform: translateY(0);
    opacity: 1;
  }
  83% {
    transform: translateY(0);
    opacity: 1;
  }
  99% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
}

.wc-tooltip {
  position: absolute;
  left: 50%;
  top: -72px;
  background-color: #212326;
  border-radius: 5px;
  color: #fff;
  padding: 20px 30px;
  min-width: 220px;
  transform: translateX(-50%);
}

.tooltip-close {
  position: absolute;
  right: 7px;
  top: 10px;
  background: url(../image/wc_sprites.png) no-repeat -386px -36px;
  background-size: 416px 384px;
  width: 10px;
  height: 10px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  text-indent: -99em;
  overflow: hidden;
}

.tooltip-content {
  white-space: nowrap;
  font-size: 14px;
  margin-bottom: 0;
}

.wc-tooltip::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -8px;
  margin-left: -10px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 8px solid #212326;
}

.cc-typer-window {
  position: relative;
  height: 400px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  .scroll-content {
    height: 100%;
    > div {
      height: 100%;
    }
  }
}

.cc-typer-window--no-link {
  padding-top: 10px;
}

.cc-lists-area {
  height: 100%;
  /*overflow: auto;*/
  font-size: 13px;
  padding: 0 10px;
}

.cc-typer-area {
  border-top: 1px solid #ddd;
  height: 120px;
  margin: 2px;
}

.cc-typer-area textarea {
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  padding: 10px;
  resize: none;
  font-size: 13px;
}

.cc-message-item {
  word-wrap: break-word;
  @include m(disable-copy) {
    user-select: none;
  }
}

// .participants-li .cc-icon {
//     width: 30px;
//     height: 20px;
//     float: right;
//     text-indent: -99em;
//     overflow: hidden;
//     background-image: url(../image/wc_sprites.png);
//     background-repeat: no-repeat;
//     background-size: 416px 384px;
//     background-position: -71px -229px;
//     margin-top: 2px;
// }

.cc-typer-tips {
  text-align: center;
}

.cc-typer-tips .alert {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 34px;
  padding: 0;
  margin: 0;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wr-content {
  position: relative;
  width: 70%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  .minimize-button{
    top: 4px;
  }
  @media screen and (max-width: 460px) {

  }
}

.wr-content img {
  flex: 0 0 auto;
  box-sizing: content-box;
  max-height: 200px;
  max-width: 400px;
  &.has-desc {
    max-height: 80px;
  }
}

.wr-tile {
  margin: 0 auto;
  margin-top: 40px;
  max-width: 780px;
  font-size: 30px;
  text-align: center;
  color: #232333;
}

.wr-default__title {
  display: inline-flex;
  color: #222230;
  margin-bottom: 16px;
  font-size: 20px;
  line-height: 24px;
  width: 100%;
  justify-content: center;
}
.wr-topic {
  font-size: 14px;
  color: rgba(4, 4, 19, 0.56);
}

.wr-customize-content {
  min-height: 67%;
  background-color: #F7F7FC;
  display: flex;
  justify-content: center;
  &.has-video {
    background-color: initial;
    align-items: flex-end;
    @media screen and (max-width: 700px){
      & {
        background-color: #F7F7FC;
        align-items: center;
      }
    }

    &.chat-open {
      @media screen and (max-width: 1100){
        & {
          background-color: #F7F7FC;
          align-items: center;
        }
      }
    }
  }
  &.is-default {
    height: 0;
    min-height: 0;
  }
  .video-container {
    position: relative;
    display: inline;
    display: flex;
    flex-direction: column;
    width: 700px;
    height: 394px;
    background-color: black;
    &.is-ie {
      flex-direction: row;
    }
    justify-content: flex-end;
    @media screen and (max-width: 700px){
      & {
        justify-content: center;
        width: 100%;
        height: auto;
      }

    }

    &.chat-open {
      @media screen and (max-width: 1100px){
        & {
          justify-content: center;
          width: 100%;
          height: auto;
        }
      }
    }
  }
  .video-content {
    max-width: 100%;
    max-height: 100%;

    @media screen and (max-width: 700px){
      & {
        width: 100%;
        margin-top: 0;
      }

    }

    @media screen and (max-height: 900px) and (min-width: 1000px){
      & {
        width: 800px;
      }
    }

    &.chat-open {
      @media screen and (max-width: 1100px){
        & {
          width: 100%;
          margin-top: 0;
        }
      }
      @media screen and (max-height: 900px) and (min-width: 1400px){
        & {
          width: 800px;
        }
      }
    }

    &::-webkit-media-controls-fullscreen-button {
      display: none;
    }
    &.mask {
      position: absolute;
      width: 100%;
      z-index: 2;
      background: #F7F7FC;
      display: flex;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      top: 0;
      bottom: 0;
      .wr-loading-icon {
        transform: scale(1.5);
      }
      .reload-video__btn {
        margin-top: 16px;
      }
    }
  }

  .wr-none-video-content {
    padding-left: 46px;
    padding-right: 46px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

.wr-default {
  text-align: center;
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 64px;
  margin-bottom: 40px;
  &.is-default {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 0;
  }
  @media screen and (max-height: 800px) and (min-width: 1000px){
    & {
      margin-top: 30px;
      margin-bottom: 20px;
    }
  }
  &.chat-open {
    @media screen and (max-height: 800px) and (min-width: 1400px){
      & {
        margin-top: 30px;
        margin-bottom: 20px;
      }
    }
  }
}

.wr-leave-btn {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 48px;
}

.wr-footer {
  color: #6E7680;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 17px;
  justify-content: center;
  height: 47px;
  line-height: 47px;

  a {
    text-decoration: underline;
    color: #6E7680;
  }
}

.wr-loading {
  display: inline-block;
}

.wr-loading-icon {
  @include circle-loading-icon;
  animation: 1.5s linear 0s normal none infinite rotate360;
}

.wr-topic-layout {
  font-size: 18px;
  margin: 0 auto;
  margin-top: 40px;
  max-width: 780px;
  color: #767788;
  text-align: center;
  .wr-topic {
    margin-left: 30px;
  }
}

.wr-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.wr-desc {
  margin: 0 auto;
  margin-top: 16px;
  font-size: 16px;
  overflow: hidden;
  word-break: break-word;
  color: #222230;
  line-height: 24px;
  max-width: 528px;
  text-align: center;
  width: 100%;
}

.logo-split {
  margin: 0 30px;
  width: 1px;
  height: 60px;
  background-color: #d8d8d8;
  display: inline-block;
  vertical-align: bottom;
}

.ant-message {
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: fixed;
  z-index: 1010;
  width: 100%;
  top: 16px;
  left: 0;
  pointer-events: none;
}

.ant-message-notice {
  padding: 8px;
  text-align: center;
}

.ant-message-notice:first-child {
  margin-top: -8px;
}

.ant-message-notice-content {
  background: #fff;
  border-radius: 4px;
  display: inline-block;
  pointer-events: all;
}

.ant-message-custom-content {
  padding: 10px 16px;
  border-radius: 4px;
  -webkit-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.ant-message-success {
  color: #207824;
  background-color: #ddf0dd;
}

.ant-message-error {
  color: #fff;
  background-color: #d9534f;
}

.ant-message-warning {
  color: #826919;
  background-color: #fff6d9;
}

.ant-message-info .anticon,
.ant-message-loading .anticon {
  color: #1890ff;
}

.ant-message .anticon {
  display: none;
  margin-right: 8px;
  font-size: 16px;
  top: 1px;
  position: relative;
}

.ant-message-notice.move-up-leave.move-up-leave-active {
  -webkit-animation-name: MessageMoveOut;
  animation-name: MessageMoveOut;
  overflow: hidden;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
}

@-webkit-keyframes MessageMoveOut {
  0% {
    opacity: 1;
    max-height: 150px;
    padding: 8px;
  }
  to {
    opacity: 0;
    max-height: 0;
    padding: 0;
  }
}

@keyframes MessageMoveOut {
  0% {
    opacity: 1;
    max-height: 150px;
    padding: 8px;
  }
  to {
    opacity: 0;
    max-height: 0;
    padding: 0;
  }
}

.nonverbal {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  padding: 10px 0;
  position: relative;
  background: #ffffff;
}

.nonverbal:after {
  content: '';
  position: absolute;
  height: 1px;
  background: #dddddd;
  width: 97%;
  bottom: 0;
}

.nonverbal-item {
  font-size: 12px;
  width: 65px;
  text-align: center;
  color: #747487;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .nonverbal-item__more-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.nonverbal-more-bubble .nonverbal-item {
  width: auto;
}

.nonverbal-more-bubble > .nonverbal-item,
.nonverbal-more-bubble > .nonverbal-icon {
  display: inline-block;
}

.more-icon.selected.isHost .nonverbal-more-bubble {
  padding-top: 5px;
}

.nonverbal-icon {
  width: 20px;
  height: 20px;
  display: block;
  background-image: url(../image/wc_sprites.png);
  background-repeat: no-repeat;
  background-size: 416px 384px;
  background-color: transparent;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  cursor: pointer;
}

.nonverbal-icon:hover,
.nonverbal-icon.selected {
  box-shadow: 0 0 0 3px #f5f5f5, 0 0 0 4px #bbb;
  -webkit-box-shadow: 0 0 0 3px #f5f5f5, 0 0 0 4px #bbb;
}

.participants-li .nonverbal-icon {
  cursor: default;
}

.participants-li .nonverbal-icon:hover {
  box-shadow: none;
}

.nonverbal-more-bubble {
  position: absolute;
  left: -130px;
  bottom: 55px;
  white-space: nowrap;
  padding: 15px 10px;
  background: #f7f7f7;
  box-shadow: 0 1px 9px 1px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 1px 9px 1px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 4px;
  border: 1px solid #bbb;
}

.nonverbal-more-bubble:after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -10px;
  margin-left: 39px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #f7f7f7;
}

.nonverbal-more-bubble:before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -11px;
  margin-left: 38px;
  width: 0;
  height: 0;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-top: 11px solid #bbb;
}

.attendee-more-bubble {
  left: -183px;
}

.attendee-more-bubble:after {
  margin-left: 76px;
}

.attendee-more-bubble:before {
  margin-left: 75px;
}

.nonverbal-icon-button {
  margin: 0 10px;
}

.nonverbal-count {
  line-height: 2;
}

.nonverbal-icon.yes-icon {
  background-position: -6px -254px;
}

.nonverbal-icon.no-icon {
  background-position: -38px -254px;
}

.nonverbal-icon.slower-icon {
  background-position: -69px -254px;
}

.nonverbal-icon.faster-icon {
  background-position: -102px -254px;
}

.nonverbal-icon.more-icon {
  background-position: -166px -254px;
}

.clear-icon-wrapper {
  display: flex;
  justify-content: center;
}

.nonverbal-icon.clear-icon {
  background-position: -132px -254px;
}

.nonverbal-icon.like-icon {
  background-position: -198px -254px;
}

.nonverbal-icon.dislike-icon {
  background-position: -229px -254px;
}

.nonverbal-icon.handshake-icon {
  background-position: -262px -254px;
}

.nonverbal-icon.away-icon {
  background-position: -294px -254px;
}

.nonverbal-icon.coffee-icon {
  background-position: -326px -254px;
}

.nonverbal-icon.clap-icon {
  background-position: -358px -254px;
}

.nonverbal-icon.raisehand-icon {
  background-position: -69px -149px;
}

.cancel-spotlight {
  position: absolute;
  left: 10px;
  top: 10px;
  font-size: 13px;
  color: #fff;
  background: rgba(64, 69, 73, 0.5);
  padding: 5px 10px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
}

.cancel-spotlight:hover {
  background: rgba(64, 69, 73, 0.8);
  cursor: pointer;
}

.cancel-spotlight:active {
  background: rgba(64, 69, 73, 1);
}

.footer-item-disabled {
  opacity: 0.5;
}

#wc-footer .footer-item-disabled .join-audio:hover {
  background: transparent;
}

.sharing-copy-btn {
  position: absolute;
  color: #fff;
  left: 10px;
  top: 10px;
  z-index: 99999;
  padding: 3px 10px;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.7);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.sharing-copy-btn:hover {
  background: rgba(0, 0, 0, 0.84);
}

.text-breaker {
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}

// .participants-li__in-sharer-icon {
//     float: right;
//     background: url(../image/wc_sprites.png)  no-repeat -263px -320px;
//     background-size: 416px 384px;
//     width: 18px;
//     height: 15px;
//     margin: 7px 4px 0 0;
// }

// .participants-li__rec-icon {
//     float: right;
//     background: url(../image/wc_sprites.png)  no-repeat -40px -354px;
//     background-size: 416px 384px;
//     width: 16px;
//     height: 12px;
//     margin: 7px 12px 0 0;
//     transform: scale(1.4);
//     animation: rec-cloud-animation2 1.5s steps(1) infinite;
// }

// .participants-li__rec-icon--local {
//     background-position: -169.2px -353px;
//     width: 14px;
//     height: 14px;
//     margin-top: 7px;
//     animation: rec-local-animation 1.5s steps(1) infinite;
// }

@keyframes rec-cloud-animation2 {
  0%,
  100% {
    background-position: -6px -172px;
  }
  50% {
    background-position: -34px -172px;
  }
}

@keyframes rec-local-animation {
  0%,
  100% {
    background-position: -137px -353px;
  }
  50% {
    background-position: -169.2px -353px;
  }
}

/* CSS accessibility outline for checkbox */
.ax-outline-important {
  outline: 2px solid transparent !important;
  outline-offset: 1px !important;
  transition: 0.2s all ease-in !important;
}

.ax-outline-important:focus {
  outline: 1px solid #fff !important;
  outline-offset: -1px !important;
}

/* CSS accessibility outline for text input */
input[type='text'] {
  outline: 2px solid transparent !important;
  outline-offset: 2px !important;
}

input[type='text']:focus {
  outline: 2px solid #2d8cff;
  outline-offset: 1px !important;
}

/* CSS accessibility outline for button with dark color and background*/
.ax-outline {
  outline: 2px solid transparent;
  outline-offset: 1px;
  transition: 0.2s all ease-in;
}

.ax-outline:focus {
  outline: 1px solid #fff;
  outline-offset: -1px;
}

/* CSS accessibility outline for button with dark color and background with offset 1 */
.ax-outline-offset-1 {
  outline: 2px solid transparent;
  outline-offset: 2px;
  transition: 0.2s all ease-in;
}

.ax-outline-offset-1:focus {
  outline: 1px solid #fff;
  outline-offset: -1px;
}

/* CSS accessibility outline for button with blue color */

.ax-outline-blue:focus {
  outline: 2px solid #2d8cff;
  outline-offset: 1px;
}

/* CSS accessibility outline for button with blue color */

.ax-outline-blue-important:focus {
  outline: 2px solid #2d8cff !important;
  outline-offset: 1px !important;
}

/* CSS accessibility outline for all dialog tab button */
#dialog-join .tab-button,
#dialog-invite .tab-button {
  width: 100%;
  border: none;
  background-color: transparent;
  outline: 2px solid transparent;
  outline-offset: 2px;
  transition: 0.2s all ease-in;
}

#dialog-join .tab-button:focus,
#dialog-invite .tab-button:focus {
  outline: 1px solid #fff;
  outline-offset: 0;
}

.audio-video-menu-button {
  height: 100%;
  padding: 0;
  border-radius: 0;
  color: transparent;
  background-color: transparent !important;
  border: none;
}

.audio-video-menu-button:focus {
  outline: 1px solid #fff !important;
  outline-offset: -1px;
}

.button-without-style {
  border: none;
  background-color: transparent;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.button-without-style:focus {
  outline: 1px solid #2d8cff;
  outline-offset: 5px;
}

.button-toast-style {
  font-weight: bold;
  height: 24px;
  border-radius: 6px;
  font-size: 13px;
  margin-left: 10px;
}

// .waitingRoom-scrollbar {
//     max-height: 136px;
//     margin: 0 6px
// }
.waitingRoom-scrollbar {
  max-height: 136px;
  margin: 0 4px;
}

.participant-scrollbar {
  margin: 0 6px;
  margin: 0 4px;
  flex: 5;
  min-height: 42px;
}

.auto-width {
  width: auto !important;
}

.button-margin-right {
  margin-right: 5px;
}

.emailIcon {
  opacity: 1;
}

.emailIcon:active {
  opacity: 0.8;
}

.text-align-center {
  text-align: center;
}

#wc-footer .open .dropdown-toggle.btn-default {
  background-color: #424345 !important;
}
#wc-footer .open .interpretation-menu .dropdown-toggle.btn-default {
  background-color: transparent !important;
}
.webinar-simulive-banner {
  position: fixed;
  height: 48px;
  top: 0;
  width: 100%;
  line-height: 48px;
  z-index: 99999;
  background: rgb(0, 0, 0);
  color: #fff;
  font-size: 15px;
  text-align: center;
}
.webinar-simulive-banner_close {
  position: absolute !important;
  right: 20px;
  top: 7px;
  border: none !important;
  background: transparent !important;
}
// Please DO NOT change those COMMON any more.
.common-window-2 {
  position: relative;
  border: 1px solid rgba(186, 186, 204, 0.2);
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.5);
  border-radius: 12px;
  box-sizing: border-box;
  pointer-events: auto;
  background-color: #ffffff;
  overflow: hidden;
  position: relative;
  .common-window-2__container {
    display: flex;
    flex-direction: column;
    padding: 0;
    .common-window-2__header {
      cursor: move;
    }
    .common-window-2__hr {
      height: 1px;
      background: #ededf4;
      position: relative;
    }
    .common-window-2__content {
      flex: 1;
      // height: 0;
    }
    .common-window-2__footer {
      padding: 9px;
    }
  }
}

.join-by-call-out-specific-number-text {
  margin: 50px auto 20px auto;
  text-align: center;
  font-size: 16px;
}

.join-by-call-out-specific-number {
  margin: 50px auto 20px auto;
  text-align: center;
  font-size: 20px;
}

.join-by-call-out-specific-number-warning {
  margin: 20px auto 20px auto;
  text-align: center;
  color: red;
}

.join-by-phone-warning-text {
  width: 350px;
  font-size: 16px;
  color: red;
  margin: 50px auto 50 auto;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
input::-ms-clear {
  display: none;
}
.root-inner,
.meeting-app,
.meeting-client,
.meeting-client-inner,
.webinar-client-inner {
  height: 100%;
}
.black-link-text {
  text-decoration: underline;
  color: black;
  &:hover,&:focus {
    color: black;
  }
}

.flex-between {
  @include flex-between;
}

.flex-center {
  @include flex-center;
}

.flex-row-center {
  @include flex-row-center
}

.flex-row-between {
  @include flex-row-between
}

.flex-column {
  @include flex-column;
}
.flex-column-center {
  @include flex-column-center;
}

.no-word-break {
  white-space: nowrap;
}

.word-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.tesla-ui {
  #wc-container-right {
    color: #F5F5F5;
    background: #242424;
    border: none;
    box-shadow: 0px 8px 24px rgb(35 35 51 / 10%);
  }
  .participants-li:hover {
    background: none;
  }
}

.minimize-mode{
  .ReactModal__Overlay {
    // display: none !important;
    left: -9999px!important;
    top: -9999px!important;
    visibility: hidden;
  }
  #notificationManager{
    display: none !important;
  }
  .notification-message-wrap{
    display: none !important;
  }
  #wc-container-right{
    // display: none !important;
    position: absolute!important;
    left: -9999px!important;
    top: -9999px!important;
    visibility: hidden;
  }
  .bhold, .meeting-on-hold {
    display: none !important;
  }
  .wc-toast{
    display: none !important;
  }
  #zmu-toast-container{
    display: none !important;
  }
}


@keyframes cloud-record-animation {
  0%,
  100% {
    background-position: -71px -353px;
  }
  50% {
    background-position: -103px -353px;
  }
}
@keyframes cloud-record-animation-2 {
  0%,
  100% {
    background-position: -192px -32px;
  }
  50% {
    background-position: -208px -32px;
  }
}

@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
// overwrite bootstrap's blockquote style
blockquote {
  padding: unset;
  border: unset;
  font-size: unset;
}

.dropdown-toggle::after,
.dropup .dropdown-toggle::after {
  display: none;
}

.sr-only{
  opacity: 0;
  position: absolute;
}
