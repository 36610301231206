.dark-dropdown {
  font-size: 13px;
  background: #000000fc;
  border: 1px solid rgba(255, 255, 255, 0.12);
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.3);
  // backdrop-filter: blur(20px);
  border-radius: 8px;
  text-align: left;

  a:focus {
    outline: 1px solid #ffffff;
  }
  .dropdown-header {
    color: #f5f5f5;
    padding-left: 16px;
    font-weight: bold;
  }
  &.new-LT__language-dropdown {
    .dropdown-item  {
      padding-left: 10px;
    }
    &.translation-list {
      max-height: 400px;
      overflow: auto;
    }
    &.transcription-list {
      max-height: 400px;
      overflow: auto;
    }
    &::-webkit-scrollbar-thumb {
      background: rgb(189 189 189 / 50%);;
    }
    &::-webkit-scrollbar {
      width: 8px;
    }
  }

  .dropdown-item  {
    margin: 0 2px;
    padding-left: 28px;
    color: rgba(255, 255, 255, 0.8);
    @include flex-row-center;
    &:hover {
      background: #2b2b2b;
    }
    &:focus {
      background: #2b2b2b;
    }
  }
  &.center-of-footer-button {
    left: 50%;
    transform: translateX(-50%);
    li[role='presentation'] a[role='menuitem'] {
      text-decoration: none;
      padding: 2px 24px;
    }
  }
}
//#428bca
.tip-wrapper {
  height: 0;
}

.selected-item-icon {
  width: 20px;
  margin-left: -14px;
}
