@mixin header-title($feature-prefix) {

  .#{$feature-prefix}__title {
    margin: 0 auto;
    flex: 1;
    text-align: center;
  }


}


@mixin header-operate($feature-prefix) {

  .#{$feature-prefix}__operate {
    position: absolute;
    right: 10px;
    > button {
      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
}
