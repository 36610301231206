// Do NOT edit this file directly. use 'npm run sprite' to generate it automatically.

@keyframes broadcast-voice-2x-animation {
  0% {
    background-position: -0px -0px;
  }
  20% {
    background-position: -58px -0px;
  }
  40% {
    background-position: -0px -58px;
  }
  60% {
    background-position: -58px -58px;
  }
  80% {
    background-position: -116px -0px;
  }
  100% {
    background-position: -0px -0px;
  }
}

@keyframes footer-voip-talking-2x-animation {
  0% {
    background-position: -34px -150px;
  }
  17% {
    background-position: -60px -150px;
  }
  34% {
    background-position: -86px -150px;
  }
  51% {
    background-position: -112px -150px;
  }
  68% {
    background-position: -138px -150px;
  }
  85% {
    background-position: -164px -150px;
  }
  100% {
    background-position: -34px -150px;
  }
}

@keyframes phone-speaking-plist-4x-animation {
  0% {
    background-position: -58px -29px;
  }
  33% {
    background-position: -0px -58px;
  }
  66% {
    background-position: -17px -58px;
  }
  100% {
    background-position: -58px -29px;
  }
}

@keyframes plist-mic-active-4x-animation {
  0% {
    background-position: -34px -58px;
  }
  13% {
    background-position: -51px -58px;
  }
  26% {
    background-position: -68px -58px;
  }
  39% {
    background-position: -87px -0px;
  }
  52% {
    background-position: -87px -17px;
  }
  65% {
    background-position: -87px -34px;
  }
  78% {
    background-position: -87px -51px;
  }
  91% {
    background-position: -0px -75px;
  }
  100% {
    background-position: -34px -58px;
  }
}

@keyframes recording-floating-button-2x-animation {
  0% {
    background-position: -116px -92px;
  }
  50% {
    background-position: -0px -184px;
  }
  100% {
    background-position: -116px -92px;
  }
}

@keyframes recording-plist-2x-animation {
  0% {
    background-position: -34px -184px;
  }
  50% {
    background-position: -68px -184px;
  }
  100% {
    background-position: -34px -184px;
  }
}
