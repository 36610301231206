@import "color-palette";
//font size start
$base-font-size: 16px;
$font-size-map: (11px: 11px, 12px: 12px, 13px: 13px, 14px:14px, 15px: 15px, 18px: 18px);
$xxs-font-size: map-get($font-size-map, 11px);
$xs-font-size: map-get($font-size-map, 12px);
$s-font-size: map-get($font-size-map, 13px);
$sm-font-size: map-get($font-size-map, 14px);
$m-font-size: map-get($font-size-map, 15px);
$lg-font-size: map-get($font-size-map, 18px);
//font size end
//border start
$border1: 1px solid $white1;
$border2: 1px solid $black1;
$border3: 1px solid $white2;
$border4: 8px solid transparent;
$border5: 1px solid $black8;
$border6: 1px solid $blue1;
$border7: 1px solid $white8;
//border end
//border radius start
$radius-map: (4px: 4px, 6px: 6px, 8px: 8px, 10px: 10px, 100px: 100px);
$xs-radius: map-get($radius-map, 4px);
$s-radius: map-get($radius-map, 6px);
$m-radius: map-get($radius-map, 8px);
$lg-radius: map-get($radius-map, 10px);
$full-circle-radius:- map-get($radius-map, 100px);
//border radius end
//header start
$header-height: 48px;
//header end