/// Block Element
/// @access public
/// @param {String} $element - Element's name
@mixin e($element) {
    &__#{$element} {
        @content;
    }
} /// Block Modifier
/// @access public
/// @param {String} $modifier - Modifier's name
@mixin m($modifier) {
    &--#{$modifier} {
        @content;
    }
}

@mixin override-parent{
    &#{&}{
        @content;
    }
}