%img-sprite-icon-base {
  display: inline-block;
  background: url('../image/wc_sprites.png');
  background-size: 416px 384px;
}

%img-sprite2-icon-base {
  display: inline-block;
  background: url('../image/wc_sprites2.png');
  background-size: 416px 384px;
}

%img-sprite-icon-filetype {
  display: inline-block;
  background: url('../image/filetypes.png');
  background-size: 174px 450px;
}
