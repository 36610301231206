@mixin checkbox-icon($checked: false) {
  @extend %img-sprite-icon-base;

  background-position: if($checked, -344px -288px, -376px -287px);
  @include size(16px, 17px);
}

@mixin radiobox-icon($checked: false) {
  @extend %img-sprite-icon-base;

  background-position: if($checked, -8px -320px, -48px -319px);
  @include size(16px, 17px);
}

@mixin full-screen-collapsed-icon($hovered: false) {
  @extend %img-sprite-icon-base;

  background-position: if($hovered, -305px -97px, -289px -97px);
  @include size(13px, 13px);
}

@mixin full-screen-expanded-icon($hovered: false) {
  @extend %img-sprite-icon-base;

  background-position: if($hovered, -337px -97px, -321px -97px);
  @include size(13px, 13px);
}

@mixin pause-play-icon {
  @extend %img-sprite-icon-base;

  background-position: -233px -319px;
  @include size(15px, 18px);
}

@mixin resume-play-icon {
  @extend %img-sprite-icon-base;

  background-position: -201px -319px;
  @include size(15px, 18px);
}

@mixin sharer-launch-icon {
  @extend %img-sprite-icon-base;

  background-position: -131px -315px;
  @include size(26px, 27px);
}

@mixin sharer-permission-icon {
  @extend %img-sprite-icon-base;

  background-position: -281px -152px;
  @include size(13px, 17px);
}
@mixin arrow-up-icon {
  @extend %img-sprite-icon-base;

  background-position: -281px -160px;
  @include size(13px, 10px);
}

@mixin q-a-entry-icon {
  @extend %img-sprite-icon-base;

  background-position: -71px -317px;
  @include size(26px, 22px);
}

@mixin setting-icon1 {
  @extend %img-sprite-icon-base;

  background-position: -274px -289px;
  @include size(12px, 13px);
}

@mixin close-icon1 {
  @extend %img-sprite-icon-base;

  background-position: -307px -291px;
  @include size(10px, 10px);
}

@mixin live-answer-icon {
  @extend %img-sprite-icon-base;

  background-position: -11px -289px;
  @include size(10px, 15px);
}

@mixin common-expand-icon1 {
  border: solid #0e71eb;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 2px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  position: relative;
  bottom: 2px;
}

@mixin comment-icon1 {
  @extend %img-sprite-icon-base;

  background-position: -112px -289px;
  @include size(16px, 14px);
}

@mixin vote-icon1($voted: false) {
  @extend %img-sprite-icon-base;

  background-position: if($voted, -41px -288px, -81px -288px);
  @include size(15px, 16px);
}

@mixin applause-icon {
  @extend %img-sprite-icon-base;

  background-position: -102px -318px;
  @include size(19px, 20px);
}

@mixin vote-disabled-icon {
  @extend %img-sprite-icon-base;

  background-position: -153px -288px;
  @include size(15px, 16px);
}

@mixin dismiss-icon {
  @extend %img-sprite-icon-base;

  background-position: -237px -292px;
  @include size(7px, 9px);
}

@mixin invite-icon {
  @extend %img-sprite-icon-base;

  background-position: -129px -6px;
  @include size(30px, 23px);
}

@mixin participants-icon {
  @extend %img-sprite-icon-base;

  background-position: -161px -6px;
  @include size(28px, 25px);
}

@mixin poll-icon {
  @extend %img-sprite-icon-base;

  background-position: 32px -74px;
  @include size(25px, 22px);
}

@mixin chat-icon {
  @extend %img-sprite-icon-base;

  background-position: -130px -219px;
  @include size(28px, 25px);
}

@mixin no-record-icon {
  @extend %img-sprite-icon-base;

  background-position: -260px -4px;
  @include size(24px, 24px);
}

@mixin recording-icon {
  @extend %img-sprite-icon-base;

  background-position: -292px -4px;
  @include size(24px, 24px);
}

@mixin closed-caption-icon-normal {
  @extend %img-sprite-icon-base;

  background-position: -3px -215px;
  @include size(25px, 18px);
}

@mixin closed-caption-icon-active {
  @extend %img-sprite-icon-base;

  background-position: -35px -217px;
  @include size(25px, 18px);
}

@mixin raise-hand-icon {
  @extend %img-sprite-icon-base;

  background-position: -340px -187px;
  @include size(23px, 23px);
}

@mixin raise-hand-active-icon {
  @extend %img-sprite-icon-base;

  background-position: -366px -187px;
  @include size(23px, 23px);
}

@mixin more-button-icon {
  @extend %img-sprite-icon-base;

  background-position: -359px -227px;
  @include size(28px, 8px);
}

@mixin close-icon2 {
  @extend %img-sprite-icon-base;

  background-position: -385px -33px;
  @include size(16px, 16px);
}

@mixin chat-dropdown-close {
  @extend %img-sprite-icon-base;

  background-position: -228px -215px;
  @include size(17px, 17px);
}

@mixin chat-dropdown-minimize {
  @extend %img-sprite-icon-base;

  background-position: -244px -213px;
  @include size(17px, 17px);
}

@mixin chat-dropdown-expand {
  @extend %img-sprite-icon-base;

  background-position: -276px -215px;
  @include size(17px, 17px);
}

@mixin chat-dropdown-pop-out {
  @extend %img-sprite-icon-base;

  background-position: -213px -215px;
  @include size(17px, 17px);
}
@mixin checked-icon($type: 0) {
  @extend %img-sprite-icon-base;

  @if $type == 0 {
    background-position: -386px -6px;
    @include size(13px, 12px);
  } @else if $type == 1 {
    background-position: -193px -191px;
    @include size(16px, 16px);
  }
}

@mixin mute-icon {
  @extend %img-sprite-icon-base;

  background-position: -385px -50px;
  @include size(13px, 12px);
}

@mixin record-icon {
  @extend %img-sprite-icon-base;

  background-position: -40px -354px;
  @include size(17px, 13px);
}

@mixin local-record-icon {
  @extend %img-sprite-icon-base;

  background-position: -169.2px -353px;
  @include size(14px, 14px);
}

@mixin start-video-icon {
  @extend %img-sprite-icon-base;

  background-position: -239px -153px;
  @include size(30px, 30px);
}

@mixin stop-video-icon {
  @extend %img-sprite-icon-base;

  background-position: -192px -153px;
  @include size(30px, 30px);
}
@mixin disallow-video-icon {
  @extend %img-sprite-icon-base;

  background-position: -311px -216px;
  @include size(32px, 30px);
}
@mixin settings-icon {
  @extend %img-sprite2-icon-base;

  background-position: -338px -99px;
  @include size(21px, 24px);
}
@mixin loading-icon {
  @extend %img-sprite-icon-base;

  background-position: -578px -66px;
  @include size(26px, 26px);
}
@mixin loading-icon-white {
  display: inline-block;
  background: url('../image/wc_sprites.png');
  background-size: 1550%;
  background-position: 66.45% 9.6%;
  @include size(20px, 20px);
}
@mixin loading-icon-black {
  display: inline-block;
  background: url('../image/wc_sprites.png');
  background-size: 1550%;
  background-position: 58.25% 9.6%;
  @include size(20px, 20px);
}
@mixin breakout-room-icon {
  @extend %img-sprite-icon-base;

  background-position: -326px -322px;
  @include size(24px, 19px);
}
@mixin ask-for-help-icon {
  @extend %img-sprite-icon-base;

  background-position: -360px -322px;
  @include size(24px, 19px);
}
@mixin search-icon {
  @extend %img-sprite-icon-base;

  background-position: -369px -350px;
  @include size(19px, 19px);
}

@mixin search-icon-2 {
  @extend %img-sprite2-icon-base;

  background-position: -122px -34px;
  @include size(12px, 12px);
}

@mixin clear-icon-sm {
  @extend %img-sprite-icon-base;

  background-position: -402px -34px;
  @include size(12px, 12px);
  transform: scale(0.6);
}

@mixin leave-room-left-icon {
  @extend %img-sprite-icon-base;

  background-position: -346px -353px;
  @include size(14px, 15px);
}

@mixin exchange-room-icon {
  @extend %img-sprite-icon-base;

  background-position: -325px -355px;
  @include size(12px, 14px);
}

@mixin swicth-room-icon {
  @extend %img-sprite-icon-base;

  background-position: -297px -355px;
  @include size(12px, 14px);
}

@mixin delete-icon {
  @extend %img-sprite-icon-base;

  background-position: -300px -281px;
  @include size(30px, 30px);
}

@mixin participant-phone-icon {
  @extend %img-sprite-icon-base;

  background-position: -3px -99px;
  @include size(21px, 21px);
}

@mixin participant-phone-mute-icon {
  @extend %img-sprite-icon-base;

  background-position: -25px -98px;
  @include size(18px, 19px);
}

@mixin disallow-share-icon {
  @extend %img-sprite-icon-base;

  background-position: -291px -313px;
  @include size(29px, 29px);
}

@mixin arrow-down-icon {
  @extend %img-sprite-icon-base;

  background-position: -260px -214px;
  @include size(16px, 16px);
}

@mixin join-audio-icon {
  @extend %img-sprite-icon-base;

  background-position: -35px -2px;
  @include size(26px, 28px);
}

@mixin voip-muted-icon {
  @extend %img-sprite-icon-base;

  background-position: -99px -2px;
  @include size(26px, 26px);
}

@mixin voip-unmuted-icon {
  @extend %img-sprite-icon-base;

  background-position: -73px -3px;
  @include size(14px, 25px);
}

@mixin voip-active-icon {
  @extend %img-sprite-icon-base;

  background-position: -73px -3px;
  @include size(16px, 26px);
  animation: audio 1.5s steps(1) infinite;
}

@mixin disallow-microphone-icon {
  @extend %img-sprite-icon-base;

  background-position: -4px -184px;
  @include size(26px, 30px);
}
@mixin phone-muted-icon {
  @extend %img-sprite-icon-base;

  background-position: -34px -67px;
  @include size(29px, 27px);
}

@mixin phone-unmuted-icon {
  @extend %img-sprite-icon-base;

  background-position: 0px -67px;
  @include size(29px, 26px);
}

@mixin tab-voip-icon {
  @extend %img-sprite-icon-base;

  background-position: -167px -41px;
  @include size(17px, 14px);
}

@mixin tab-phone-icon {
  @extend %img-sprite-icon-base;

  background-position: -104px -40px;
  @include size(16px, 16px);
}

@mixin tab-call-me-icon {
  @extend %img-sprite-icon-base;

  background-position: -72px -40px;
  @include size(16px, 16px);
}
@mixin security-icon {
  @extend %img-sprite-icon-base;

  background-position: -131px -182px;
  @include size(30px, 28px);
}
@mixin close-icon-3 {
  @extend %img-sprite-icon-base;

  background-position: -400px -32px;
  @include size(16px, 16px);
}
@mixin close-icon-4 {
  @extend %img-sprite2-icon-base;

  background-position: -468px -70px;
  @include size(18px, 18px);
  @include background-scale(0.6);
}

@mixin file-pdf-icon {
  @extend %img-sprite-icon-filetype;

  background-position: -19px -9px;
  @include size(27px, 32px);
}
@mixin file-excel-icon {
  @extend %img-sprite-icon-filetype;

  background-position: -83px -9px;
  @include size(27px, 32px);
}
@mixin file-txt-icon {
  @extend %img-sprite-icon-filetype;

  background-position: -83px -89px;
  @include size(27px, 32px);
}
@mixin file-doc-icon {
  @extend %img-sprite-icon-filetype;

  background-position: -19px -169px;
  @include size(27px, 32px);
}
@mixin file-img-icon {
  @extend %img-sprite-icon-filetype;

  background-position: -83px -169px;
  @include size(27px, 32px);
}

@mixin file-video-icon {
  @extend %img-sprite-icon-filetype;

  background-position: -19px -329px;
  @include size(27px, 32px);
}
@mixin file-ppt-icon {
  @extend %img-sprite-icon-filetype;

  background-position: -19px -409px;
  @include size(27px, 32px);
}
@mixin file-audio-icon {
  @extend %img-sprite-icon-filetype;

  background-position: -83px -409px;
  @include size(27px, 32px);
}
@mixin  file-code-icon {
  @extend %img-sprite-icon-filetype;

  background-position: -19px -249px;
  @include size(27px, 32px);
}
@mixin file-html-icon {
  @extend %img-sprite-icon-filetype;

  background-position: -83px -329px;
  @include size(27px, 32px);
}
@mixin file-zip-icon {
  @extend %img-sprite-icon-filetype;

  background-position: -83px -249px;
  @include size(27px, 32px);
}
@mixin file-sketch-icon {
  @extend %img-sprite-icon-filetype;

  background-position: -19px -89px;
  @include size(27px, 32px);
}
@mixin file-general-icon {
  display: inline-block;
  background: url('../image/general-file-type.png');
  background-size: 32px 32px;
  background-position: -3px;
  @include size(27px, 32px);
}
@mixin encrypt-standard-icon {
  @extend %img-sprite-icon-base;

  background-position: -215px -187px;
  @include size(25px, 25px);
}

@mixin encrypt-enhanced-icon {
  @extend %img-sprite-icon-base;

  background-position: -246px -187px;
  @include size(25px, 25px);
}

@mixin meeting-info-icon {
  @extend %img-sprite-icon-base;

  background-position: -76px -184px;
  @include size(25px, 25px);
}

@mixin gov-cluster-icon {
  @extend %img-sprite2-icon-base;

  background-position: -370px -4.5px;
  @include size(23px, 22px);
}

@mixin copy-url-icon {
  @extend %img-sprite-icon-base;

  background-position: -168px -193px;
  @include size(12px, 12px);
}

@mixin edit-icon {
  @extend %img-sprite-icon-base;

  background-position: -2px -35px;
  @include size(15px, 15px);
}

@mixin cloud-record-icon {
  @extend %img-sprite-icon-base;

  background-position: -71px -353px;
  @include size(18px, 14px);
  animation: cloud-record-animation 1.5s steps(1) infinite;
}
@mixin cloud-record-icon-2 {
  @extend %img-sprite-icon-base;

  background-position: -192px -32px;
  @include size(15px, 15px);
  animation: cloud-record-animation-2 1.5s steps(1) infinite;
}


@mixin circle-loading-icon {
  @extend %img-sprite-icon-base;

  background-position: 26px 37.6px;
  @include size(24px, 24px);
  animation: 1.5s linear 0s normal none infinite rotate360;
}

@mixin circle-loading-black {
  @extend %img-sprite-icon-base;

  background-position: -226px -34px;
  @include size(28px, 28px);
  animation: 1.5s linear 0s normal none infinite rotate360;
}

@mixin collapse-icon {
  @extend %img-sprite-icon-base;

  background-position: -278px -231px;
  @include size(16px, 16px);
}
@mixin expand-icon {
  @extend %img-sprite-icon-base;

  background-position: -260px -231px;
  @include size(16px, 16px);
}

@mixin speaker-icon {
  @extend %img-sprite2-icon-base;

  background-position: -11px -72px;
  @include size(16px, 16px);
  @include background-scale(0.6);
}

@mixin gallery-icon {
  @extend %img-sprite2-icon-base;

  background-position: -39px -72px;
  @include size(16px, 16px);
  @include background-scale(0.6);
}

@mixin sidebysidespeaker-icon {
  @extend %img-sprite2-icon-base;

  background-position: -212px -72px;
  @include size(16px, 16px);
  @include background-scale(0.6);
}

@mixin sidebysidegallery-icon {
  @extend %img-sprite2-icon-base;

  background-position: -184px -72px;
  @include size(16px, 16px);
  @include background-scale(0.6);
}

@mixin swap-icon {
  @extend %img-sprite2-icon-base;

  background-position: -334px -57px;
  @include size(16px, 16px);
  @include background-scale(0.5);
}

@mixin pin-icon {
  @extend %img-sprite2-icon-base;

  background-position: -81px -84px;
  @include size(16px, 16px);
  @include background-scale(0.7);
}

@mixin spotlight-icon {
  @extend %img-sprite2-icon-base;

  background-position: -114px -84px;
  @include size(16px, 16px);
  @include background-scale(0.7);
}

@mixin view-mute-computer {
  @extend %img-sprite2-icon-base;

  background-position: -148px -84px;
  @include size(16px, 16px);
  @include background-scale(0.7);
}

@mixin view-mute-phone {
  @extend %img-sprite2-icon-base;

  background-position: -181px -84px;
  @include size(16px, 16px);
  @include background-scale(0.7);
}

@mixin suspension-micro-icon {
  @extend %img-sprite2-icon-base;

  background-position: -7px -9px;
  @include size(16px, 16px);
}

@mixin suspension-small-icon {
  @extend %img-sprite2-icon-base;

  background-position: -35px -9px;
  @include size(16px, 16px);
}

@mixin suspension-medium-icon {
  @extend %img-sprite2-icon-base;

  background-position: -63px -9px;
  @include size(16px, 16px);
}

@mixin suspension-big-icon {
  @extend %img-sprite-icon-base;

  background-position: -327px -162px;
  @include size(16px, 16px);
}

@mixin suspension-large-icon {
  @extend %img-sprite2-icon-base;

  background-position: -91px -9px;
  @include size(16px, 16px);
}

@mixin trash-icon {
  @extend %img-sprite-icon-base;

  background-position: -394px -99px;
  @include size(16px, 16px);
}

@mixin suspension-micro-active-icon {
  @extend %img-sprite2-icon-base;

  background-position: -7px -32px;
  @include size(16px, 16px);
}

@mixin suspension-small-active-icon {
  @extend %img-sprite2-icon-base;

  background-position: -35px -32px;
  @include size(16px, 16px);
}

@mixin suspension-medium-active-icon {
  @extend %img-sprite2-icon-base;

  background-position: -63px -32px;
  @include size(16px, 16px);
}

@mixin suspension-big-active-icon {
  @extend %img-sprite-icon-base;

  background-position: -348px -152px;
  @include size(16px, 16px);
}

@mixin suspension-large-active-icon {
  @extend %img-sprite2-icon-base;

  background-position: -91px -32px;
  @include size(16px, 16px);
}

@mixin view-raise-hand-icon {
  @extend %img-sprite2-icon-base;

  background-position: -198px -57px;
  @include size(16px, 16px);
}

@mixin full-screen-collapsed-icon-new() {
  @extend %img-sprite2-icon-base;

  background-position: -148px -8px;
  @include size(16px, 16px);
}

@mixin full-screen-expanded-icon-new() {
  @extend %img-sprite2-icon-base;

  background-position: -120px -8px;
  @include size(16px, 16px);
}

@mixin flag-icon {
  @extend %img-sprite2-icon-base;

  background-position: -260px -231px;
  @include size(12px, 12px);
}
@mixin success-icon {
  @extend %img-sprite2-icon-base;

  background-position: -6px -102px;
  @include size(20px, 20px);
}

@mixin rmcAdmin-icon {
  @extend %img-sprite2-icon-base;

  background-position: -9px -137px;
  @include size(24px, 24px);
}
@mixin options-icon {
  @extend %img-sprite2-icon-base;

  background-position: -148px -32px;
  @include size(16px, 16px);
}
@mixin unencryption-icon {
  @extend %img-sprite2-icon-base;

  background-position: -178px -33px;
  @include size(13px, 14px);
}
@mixin encryption-icon {
  @extend %img-sprite2-icon-base;

  background-position: -206px -33px;
  @include size(13px, 14px);
}
@mixin pause-rec-icon {
  @extend %img-sprite2-icon-base;

  background-position: -235px -8px;
  @include size(16px, 16px);
}
@mixin resume-rec-icon {
  @extend %img-sprite2-icon-base;

  background-position: -291px -8px;
  @include size(16px, 16px);
}
@mixin stop-rec-icon {
  @extend %img-sprite2-icon-base;

  background-position: -263px -8px;
  @include size(16px, 16px);
}
@mixin rec-text-icon {
  @extend %img-sprite2-icon-base;

  background-position: -202px -4.5px;
  @include size(23px, 22px);
}
@mixin pause-rec-footer-icon {
  @extend %img-sprite2-icon-base;

  background-position: -36px -100px;
  @include size(24px, 24px);
}
@mixin resume-rec-footer-icon {
  @extend %img-sprite2-icon-base;

  background-position: -100px -100px;
  @include size(24px, 24px);
}
@mixin stop-rec-footer-icon {
  @extend %img-sprite2-icon-base;

  background-position: -68px -100px;
  @include size(24px, 24px);
}
@mixin rec-text-icon2 {
  @extend %img-sprite2-icon-base;

  background-position: -316px -4.5px;
  @include size(23px, 22px);
}

@mixin success-icon2 {
  @extend %img-sprite2-icon-base;

  background-position: -260px -32px;
  @include size(16px, 16px);
}

@mixin timer-icon {
  @extend %img-sprite2-icon-base;

  background-position: -350px -5px;
  @include size(16px, 22px);
}

@mixin logo-icon {
  @extend %img-sprite2-icon-base;

  background-position: -121px -574px;
  @include size(60px, 60px);
}
@mixin gift-icon {
  @extend %img-sprite2-icon-base;

  background-position: -6px -574px;
  @include size(64px, 60px);
}
@mixin sand-clock-icon {
  @extend %img-sprite2-icon-base;

  background-position: -72px -574px;
  @include size(40px, 60px);
}
@mixin interpretation-icon {
  @extend %img-sprite2-icon-base;

  background-position: -304px -100px;
  @include size(24px, 24px);
}

@mixin lang-type-large-0 {
  @extend %img-sprite2-icon-base;

  background-position: 3px -339px;
  @include size(20px, 20px);
}
@mixin lang-type-large-1 {
  @extend %img-sprite2-icon-base;

  background-position: -15px -339px;
  @include size(20px, 20px);
}
@mixin lang-type-large-4 {
  @extend %img-sprite2-icon-base;

  background-position: -32px -339px;
  @include size(20px, 20px);
}
@mixin lang-type-large-3 {
  @extend %img-sprite2-icon-base;

  background-position: -52px -339px;
  @include size(20px, 20px);
}
@mixin lang-type-large-7 {
  @extend %img-sprite2-icon-base;

  background-position: -88px -339px;
  @include size(20px, 20px);
}
@mixin lang-type-large-2 {
  @extend %img-sprite2-icon-base;

  background-position: -71px -339px;
  @include size(20px, 20px);
}
@mixin lang-type-large-5 {
  @extend %img-sprite2-icon-base;

  background-position: -108px -339px;
  @include size(20px, 20px);
}
@mixin lang-type-large-6 {
  @extend %img-sprite2-icon-base;

  background-position: -128px -339px;
  @include size(20px, 20px);
}
@mixin lang-type-large-8 {
  @extend %img-sprite2-icon-base;

  background-position: -147px -339px;
  @include size(20px, 20px);
}

@mixin disclaimer-icon {
  @extend %img-sprite2-icon-base;

  background-position: -373px -34px;
  @include size(14px, 14px);
}

@mixin default-avatar-white {
  @extend %img-sprite2-icon-base;

  background-size: 440%;
  background-position: 99.45% 46.6%;
}

@mixin mask-circle-icon {
  @extend %img-sprite2-icon-base;

  background-position: -300px 84px;
  @include size(24px, 24px);
}

@mixin mask-heart-icon {
  @extend %img-sprite2-icon-base;

  background-position: -364px 84px;
  @include size(24px, 24px);
}

@mixin mask-rectangle-icon {
  @extend %img-sprite2-icon-base;

  background-position: -331px 84px;
  @include size(24px, 24px);
}

@mixin mask-circle-loading-icon {
  @extend %img-sprite2-icon-base;

  background-position: -2px 127px;
  @include size(28px, 28px);
}

@mixin mask-default-icon {
  @extend %img-sprite2-icon-base;

  background-position: -34px 127px;
  @include size(28px, 28px);
}

@mixin dragbar-icon {
  @extend %img-sprite2-icon-base;

  background-position: 5px 27px;
  @include size(28px, 28px);
}

@mixin stop-share-icon {
  @extend %img-sprite2-icon-base;

  background-position: -23px 28px;
  @include size(28px, 28px);
}

@mixin more-share-icon {
  @extend %img-sprite2-icon-base;

  background-position: -74px 28px;
  @include size(28px, 28px);
}
@mixin share-resume-icon {
  @extend %img-sprite2-icon-base;

  background-position: -198px 28px;
  @include size(28px, 28px);
}
@mixin share-play-icon {
  @extend %img-sprite2-icon-base;

  background-position: -46px 28px;
  @include size(28px, 28px);
}
@mixin sound-icon {
  @extend %img-sprite2-icon-base;
  
  background-position: -158px -364px;
  @include size(12px, 12px);
}

@mixin no-sound-icon {
  @extend %img-sprite2-icon-base;

  background-position: -182px -364px;
  @include size(12px, 12px);
}
@mixin share-audio-pause-icon {
  @extend %img-sprite2-icon-base;

  background-position: -126px 28px;
  @include size(28px, 28px);
}
@mixin share-audio-resume-icon {
  @extend %img-sprite2-icon-base;

  background-position: -102px 28px;
  @include size(28px, 28px);
}

@mixin link-icon {
  @extend %img-sprite2-icon-base;

  background-position: -174px -5px;
  @include size(18px, 18px);
}
