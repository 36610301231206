//color start
$grey-color-map: ( #000000: #000000,
                   #070606: #070606,
                   #202123: #202123,
                   #424345: #424345,
                   #747487: #747487,
                   #666666: #666666,
                   #232333: #232333,
                   262670t04: rgba(38, 38, 112, 0.04),
                   000000t4:rgba(0, 0, 0, 0.4),
                  );
$white-color-map: (
                  #FFFFFF: #FFFFFF,
                  #DADADA: #DADADA,
                  #f2f2f7: #f2f2f7,
                  #999999: #999999,
                  #dcdcdc: #dcdcdc,
                  FFFFFFt5: rgba(255, 255, 255, 0.35),
                  383050t01: rgba(255, 255, 255, 0.1),
                  );
$red-color-map: (#FF0000: #FF0000, #E02828: #E02828, #ff742e: #ff742e, #FF4242: #FF4242);
$green-color-map: ("#70‬C43At9": rgba(5, 5, 5, 0.9),"#27874C":#27874C);
$yellow-color-map: (#C97373: #C97373, #826919: #826919);
$orange-color-map: ( #ff8326: #ff8326, #F6979B: #F6979B);
$blue-color-map: (#0E71EB: #0E71EB,#2d8cff:#2d8cff,HOVER:#E7F1FD);

$black: map-get($grey-color-map, #000000);
$black1: map-get($grey-color-map, #070606);
$black2: map-get($grey-color-map, #202123);
$black3: map-get($grey-color-map, 000000t4);
$black4: map-get($grey-color-map, #424345);
$black5: map-get($grey-color-map, #747487);
$black6: map-get($grey-color-map, #666666);
$black7: map-get($grey-color-map, #232333);
$black8: map-get($grey-color-map, 262670t04);
$black9: rgba(23, 23, 23, 0.9);
$black10: rgba(255, 255, 255, 0.09);;
$black11: #242628;
$black12: #333333;
$black13: #262626;
$black14: #555555;
$black15: #202020;
$black16: #404549;
$black17: #354052;
$black18: #131619;
$black19: #6E7680;

$white1: map-get($white-color-map, #FFFFFF);
$white2: map-get($white-color-map, #DADADA);
$white3: map-get($white-color-map, FFFFFFt5);
$white4: map-get($white-color-map, #f2f2f7);
$white5: map-get($white-color-map, #999999);
$white6: map-get($white-color-map, 383050t01);
$white7: map-get($white-color-map, #dcdcdc);
$white8: #bababa;
$white9: #ebebeb;
$white10: #adadad;
$white11: #DDDDDD;
$white12: #F7F7FA;
$white13: #fff6d9;
$white14: #f5f5f5;
$white15: #707070;
$white16: #BABACC;
$white17: #e4e4ed;

$red1: map-get($red-color-map, #FF0000);
$red2: map-get($red-color-map, #E02828);
$red3: map-get($red-color-map, #ff742e);
$red4: map-get($red-color-map, #FF4242);
$red5: #E8173D;

$green1: map-get($green-color-map, "#70‬C43At9");
$green2: map-get($green-color-map,"#27874C");
$green3: #4bc800;
$green4: #00A832;
$green5: #23D959;

$yellow1: map-get($yellow-color-map, #C97373);
$yellow2:#ffc80f;
$yellow3:#826919;

$blue1: map-get($blue-color-map, #0E71EB);
$blue2: map-get($blue-color-map,#2d8cff);
$blue3: #3276b1;
$blue4: #285e8e;
$blue5: #2681f2;
$blue6: #0E72ED;
$blue-hover: map-get($blue-color-map,HOVER);

$orange1: map-get($orange-color-map, #ff8326);
$orange2: map-get($orange-color-map, #F6979B);
$orange3: #FCF6ED;

$brown1:#775111;
$avatar1:#27ae60;
$avatar2:#16a085;
$avatar3:#2980b9;
$avatar4:#8e44ad;
$avatar5:#34495e;
$avatar6:#f39c12;
$avatar7:#d35400;
$avatar8:#c0392b;

@mixin colors($color, $hover: $color, $focus: $color) {
  & {
    color: $color;
    &:hover {
      color: $hover
    }
    &:focus {
      color: $focus
    }
  }
}
