// Do NOT edit this file directly. use 'npm run sprite' to generate it automatically.
%img-sprite-animated-icon-base-2x {
  display: inline-block;
  background: url('../image/animation-sprite.png');
  background-size: 206px 200px;
}
%img-sprite-animated-icon-base-4x {
  display: inline-block;
  background: url('../image/animation-sprite.png');
  background-size: 103px 100px;
}

@mixin broadcast-voice-2x {
  @extend %img-sprite-animated-icon-base-2x;

  background-position: -0px -0px;
  width: 56px;
  height: 56px;
  animation: broadcast-voice-2x-animation 1s steps(1) infinite alternate;
}

@mixin footer-voip-talking-2x {
  @extend %img-sprite-animated-icon-base-2x;

  background-position: -34px -150px;
  width: 24px;
  height: 24px;
  animation: footer-voip-talking-2x-animation 1s steps(1) infinite alternate;
}

@mixin phone-speaking-plist-4x {
  @extend %img-sprite-animated-icon-base-4x;

  background-position: -58px -29px;
  width: 16px;
  height: 16px;
  animation: phone-speaking-plist-4x-animation 1s steps(1) infinite alternate;
}

@mixin plist-mic-active-4x {
  @extend %img-sprite-animated-icon-base-4x;

  background-position: -34px -58px;
  width: 16px;
  height: 16px;
  animation: plist-mic-active-4x-animation 1s steps(1) infinite alternate;
}

@mixin recording-floating-button-2x {
  @extend %img-sprite-animated-icon-base-2x;

  background-position: -116px -92px;
  width: 32px;
  height: 16px;
  animation: recording-floating-button-2x-animation 1s steps(1) infinite alternate;
}

@mixin recording-plist-2x {
  @extend %img-sprite-animated-icon-base-2x;

  background-position: -34px -184px;
  width: 32px;
  height: 16px;
  animation: recording-plist-2x-animation 1s steps(1) infinite alternate;
}