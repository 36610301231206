@import './phone-color.scss';


@keyframes rotate-infinite {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}

@mixin rotate-infinite($speed) {
    animation-name: rotate-infinite;
    animation-duration: $speed;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}


@mixin modal-inner-shadow() {
    box-shadow: rgb(35 35 51 / 10%) 0px 8px 24px;
}

@mixin scroll-bar($width: 8) {
    &::-webkit-scrollbar {
        width: $width;
    }

    &:-webkit-scrollbar-track {
        border-radius: $width / 3;
        background: rgba(0, 0, 0, 0.06);
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.08);
    }

    &::-webkit-scrollbar-thumb {
        border-radius: $width / 3;
        background: rgba(0, 0, 0, 0.12);
        -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

@mixin scroll-bar($width: 8px, $borderRadius: 3px) {
    &::-webkit-scrollbar {
        width: $width;
    }

    &::-webkit-scrollbar-track {
        border-radius: $borderRadius;
        background: rgba(0, 0, 0, 0.06);
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.08);
    }

    &::-webkit-scrollbar-thumb {
        border-radius: $borderRadius;
        background: rgba(0, 0, 0, 0.12);
        -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
    }
}

@mixin scroll-bar($width: 8px, $trackBorderRadius: 3px, $thumbborderRadius: 3px) {
    &::-webkit-scrollbar {
        width: $width;
    }

    &::-webkit-scrollbar-track {
        border-radius: $trackBorderRadius;
        background: rgba(0, 0, 0, 0.06);
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.08);
    }

    &::-webkit-scrollbar-thumb {
        border-radius: $thumbborderRadius;
        background: rgba(0, 0, 0, 0.12);
        -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
    }
}

@mixin foucs-ring() {
    outline: $outline-width $outline-style $outline-color;
    outline-offset: $outline-offset;
}