%break-in-whatever{
    word-wrap: break-word;
    word-break: break-word;
}

%break-in-space{
    word-wrap: break-word;
    word-break: keep-all;
}

%no-word-break {
    white-space: nowrap;
}

%word-ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
